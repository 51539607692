import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from './../../../components/ContentBlockWrapper'
import EmptyContent from '../../../components/EmptyContent'
import { SignatureBox } from './TabContentStyled'
import { Row, Col, Card, Tooltip, message, Popconfirm } from 'antd'
import { DeleteTwoTone } from '@ant-design/icons'
import { apiUrl } from '../../../config'

const { Meta } = Card

const SignatureManagement = props => {

  const {
    signedDocumentStore,
    loadingAnimationStore,
  } = props

  const { signatureList } = signedDocumentStore

  const handleDeleteSignature = (id_signature, name_signature) => {
    loadingAnimationStore.showSpinner(true)
    signedDocumentStore.deleteSignature(id_signature)
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        message.success(<span>Bạn đã xóa chữ ký <b>{name_signature}</b> thành công!</span>)
          .then()
      })
      .catch(err => {
        console.log(err)
        loadingAnimationStore.showSpinner(false)
      })
  }

  useEffect(() => {
    if (signatureList) return
    (async () => {
      try {
        loadingAnimationStore.setTableLoading(true)
        await signedDocumentStore.getSignatureList()

      } catch (error) {
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.setTableLoading(false)
      }
    })()
  }, [])

  return (
    <ContentBlockWrapper>
      {
        signatureList ?
          <Row gutter={[30, 30]}>
            {
              signatureList.map(signature =>
                <Col md={24} lg={12} xl={8} key={signature.id}>
                  <SignatureBox>
                    <Card
                      hoverable
                      className={'card'}
                      cover={
                        <div className={'img-box'}>
                          <img alt='signature' src={`${apiUrl}/api/v1/images/${signature.signId}`} />
                        </div>
                      }
                      actions={[
                        <Popconfirm
                          placement='top'
                          title={'Bạn có chắc chắn muốn xóa chữ ký này?'}
                          onConfirm={() => handleDeleteSignature(signature.id, signature.signName)}
                          okText='Đồng ý'
                          cancelText='Hủy bỏ'
                        >
                          <Tooltip placement='top' title='Xóa chữ ký'>
                            <DeleteTwoTone twoToneColor={'#e74c3c'} key='delete' style={{ fontSize: 20 }} />
                          </Tooltip>
                        </Popconfirm>
                        ,
                      ]}
                    >
                      <Meta title={signature.signName} />
                    </Card>
                  </SignatureBox>
                </Col>,
              )
            }
          </Row>
          : <EmptyContent description={'Bạn chưa có chữ ký nào, hãy tạo chữ ký!'} />
      }
    </ContentBlockWrapper>
  )
}

SignatureManagement.propTypes =
  {}

export default inject('signedDocumentStore', 'loadingAnimationStore')(observer(SignatureManagement))
