import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'


export const WorkScheduleRequest = {

  getPublicWorkSchedule: (from_date, to_date) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/work-schedules`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
      params: {
        from_date: from_date,
        to_date: to_date,
      },
    }),
}