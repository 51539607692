import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Divider,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Switch,
  Table,
  Tooltip,
  message,
} from 'antd'
import Search from 'antd/lib/input/Search'
import { find } from 'lodash-es'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import CreateEditUserForm from '../../components/CreateEditUserForm'
import EmptyContent from '../../components/EmptyContent'
import PageHeading from '../../components/PageHeading'
import TableFooterWrapper from '../../components/TableFooterWrapper'
import utils from '../../utils'

const { Option } = Select

const ConnectedDocumentManagePage = props => {
  const {
    commonStore,
    userManageStore,
    loadingAnimationStore,
    companyStore,
  } = props
  const {
    userList,
    totalCount,
    pageIndex,
    pageSize,
    userOrFullname,
    companyCode,
  } = userManageStore

  const { companies } = companyStore

  const [showModalUser, setShowModalUser] = React.useState(false)
  const [title, setTitle] = useState('Quản trị tài khoản')

  const tableColumns = useMemo(
    () => [
      {
        title: 'Họ tên',
        render: record => record.fullName,
      },
      {
        title: 'Tài khoản',
        render: record => record.userName,
      },
      {
        title: 'Email',
        render: record => record.email,
      },
      {
        title: 'Trực thuộc',
        render: record =>
          record?.parent
            ? `${record?.parent?.fullName} (${record?.parent?.userName})`
            : '',
      },
      {
        title: 'Trạng thái',
        render: record => (
          <Switch
            checked={record?.isEnabled}
            onChange={status => handleUpdateUserStatus(record.id, status)}
          />
        ),
      },
      {
        title: 'Tác vụ',
        render: record => (
          <>
            <Tooltip title={'Sửa thông tin tài khoản'}>
              <EditOutlined onClick={() => handleEditModal(record.id)} />
            </Tooltip>
            <Divider type={'vertical'} />
            <Tooltip title={'Xóa'}>
              <Popconfirm
                onConfirm={() => handleDeleteUser(record.id)}
                okText={'Đồng ý'}
                cancelText={'Không'}
                okType={'danger'}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                title={'Bạn có muốn xoá tài khoản này?'}>
                <DeleteOutlined style={{ color: '#fc0000' }} />
              </Popconfirm>
            </Tooltip>
          </>
        ),
      },
    ],
    [userList, pageIndex, userOrFullname]
  )

  const handleDeleteUser = useCallback(
    async userID => {
      try {
        loadingAnimationStore.showSpinner(true)
        await userManageStore.deleteUser(userID)
        if (userList.length === 1 && pageIndex !== 0) {
          userManageStore.setFilter('pageIndex', pageIndex - 1)
        }
        await userManageStore.getUserList()
        message.success('Đã xóa!')
      } catch (error) {
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    },
    [userList, pageIndex]
  )
  const handleUpdateUserStatus = useCallback(
    (userID, status) => {
      userManageStore
        .updateStatusUser(userID)
        .then(() => {
          userManageStore.getUserList()
          message.success(`Đã ${status ? 'kích hoạt' : 'tạm dừng'} người dùng`)
        })
        .catch(error => console.log(error))
    },
    [userManageStore, userList]
  )
  const handleChangePage = useCallback(page => {
    const pageIndex = page - 1
    userManageStore.setFilter('pageIndex', pageIndex)
  }, [])
  const handleCreateModal = useCallback(() => {
    setShowModalUser(true)
    commonStore.setIsEditMode(false)
  }, [])
  const handleEditModal = useCallback(userID => {
    setShowModalUser(true)
    commonStore.setIsEditMode(true)
    userManageStore.getUser(userID)
  }, [])

  const handleSearch = useCallback(keyword => {
    console.log('keyword', keyword)
    if (utils.isNullish(keyword)) {
      userManageStore.setFilter('userOrFullname', undefined)
      return
    }
    userManageStore.setFilter('userOrFullname', keyword && keyword.trim())
  }, [])

  const onCompanyChange = value => {
    userManageStore.setFilter('companyCode', value)

    let selectedCompany = find(companies, { code: value })
    setTitle(
      `Quản trị tài khoản ${selectedCompany ? selectedCompany.name : ''}`
    )
  }

  useEffect(() => {
    commonStore.setPageName(['/connected-document/manage/account'])
    userManageStore.clearStore()
  }, [])
  useEffect(() => {
    userManageStore.setFilter('pageSize', 10)
    userManageStore.setFilter('isAsc', 'DESC')
    ;(async () => {
      try {
        loadingAnimationStore.setTableLoading(true)
        await userManageStore.getUserList()
      } catch (error) {
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.setTableLoading(false)
      }
    })().catch(error => console.log(error))
  }, [pageIndex, userOrFullname, companyCode])

  return (
    <>
      <Helmet>
        <title>{'Quản trị tài khoản'}</title>
      </Helmet>
      <PageHeading title={title}>
        <Select
          style={{ width: 200 }}
          allowClear
          notFoundContent={<EmptyContent />}
          onChange={onCompanyChange}
          placeholder={'-- Chọn công ty --'}>
          {companies.map(item => (
            <Option key={item.code} value={item.code}>
              {item.name}
            </Option>
          ))}
        </Select>
      </PageHeading>
      <ContentBlockWrapper>
        <Row style={{ marginBottom: 16 }}>
          <Col flex="auto">
            <Search
              placeholder={'Tìm kiếm'}
              onSearch={handleSearch}
              allowClear={true}
            />
          </Col>
          <Col style={{ marginLeft: 16 }}>
            <Button type={'primary'} onClick={handleCreateModal}>
              <PlusCircleOutlined />
              Thêm mới tài khoản
            </Button>
          </Col>
        </Row>
        <Table
          rowKey={record => record.id}
          dataSource={userList}
          columns={tableColumns}
          pagination={false}
          loading={{
            tip: 'Đang tải tài khoản...',
            spinning: loadingAnimationStore.tableLoading,
          }}
        />
        <TableFooterWrapper>
          <Pagination
            current={pageIndex + 1}
            pageSize={pageSize}
            total={totalCount}
            onChange={handleChangePage}
            hideOnSinglePage={true}
          />
        </TableFooterWrapper>
        <CreateEditUserForm
          modalVisibleProps={showModalUser}
          modalCancelProps={() => setShowModalUser(false)}
        />
      </ContentBlockWrapper>
    </>
  )
}

ConnectedDocumentManagePage.propTypes = {
  commonStore: PropTypes.object,
}

export default inject(
  'commonStore',
  'userManageStore',
  'loadingAnimationStore',
  'companyStore'
)(observer(ConnectedDocumentManagePage))
