import React, { useEffect, useCallback, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Helmet } from 'react-helmet/es/Helmet'
import PageHeading from '../../components/PageHeading'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { Container } from '../../layouts/Container/Container'
import {
  Form,
  Select,
  Button,
  Input,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Switch,
  Upload,
  message,
  Tooltip,
  Tag, Space,
} from 'antd'
import EmptyContent from '../../components/EmptyContent'
import {
  DraggerUploadStyled,
  FormActionFooter,
  UploadFileListWrapper,
  UploadFileListItem, SelectList, SelectTitle,
} from './ConnectedDocumentCreatePageStyled'
import { ISO_DATE_FORMAT } from '../../constants'
import { CloudUploadOutlined, DeleteOutlined, PaperClipOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import validator from '../../validator'
import loadingAnimationStore from '../../stores/loadingAnimationStore'
import { blue, yellowPrimary } from '../../color'
import SelectPeoplePopup from '../../components/SelectPeoplePopup'
import { AvatarHasName } from '../../components/Common/Avatar'
import { toJS } from 'mobx'
import SelectGroupPopup from '../../components/SelectGroupPopup'

const { Option } = Select
const { TextArea } = Input
const { Dragger } = Upload

const ConnectedDocumentCreatePage = props => {

  const {
    commonStore,
    history,
    fileStore,
    documentStore,
    signedDocumentStore,
    selectPeopleStore,
  } = props
  const {
    handleOpenSelectUserPopup,
    isVisibleSelectUserPopup,
    handleCancelSelectUser,
    handleCancelSelectGroup,
    selectUserData,
    selectGroupData,
    handleOpenSelectGroupPopup,
    isVisibleSelectGroupPopup,
  } = selectPeopleStore
  const [form] = Form.useForm()

  const [fileList, setFileList] = useState([])
  const [selectedUserList, setSelectedUserList] = useState([])
  const [loadings, setLoadings] = useState([])
  useEffect(() => {
    setSelectedUserList([...toJS(selectUserData), ...toJS(selectGroupData)])
  }, [selectUserData, selectGroupData])

  const enterLoading = index => {
    setLoadings(prevLoadings => {
      const newLoadings = [...prevLoadings]
      newLoadings[index] = true
      return newLoadings
    })
  }
  const handleCancelCreate = useCallback(() => {
    form.resetFields()
    history.push('/connected-document/view/outgoing')
    setSelectedUserList([])
    selectPeopleStore.clearSelectPeopleStore()
  }, [form, history, selectedUserList])

  const handleAddFileToUpload = useCallback(() => false, [])

  const handleChangeFile = useCallback(info => {
    if (info.fileList.length === 0) {
      form.setFieldsValue({ attachments: undefined })
      setFileList([])
      return
    }
    const filteredFileList = info.fileList
      .filter((elem, index, fileArray) => fileArray.findIndex(file => (file.name === elem.name)) === index)
    setFileList(filteredFileList)
  }, [])

  const handleRemoveFileFromUploadList = useCallback(fileUID => {
    const newFileList = fileList.filter(file => file.uid !== fileUID)
    setFileList(newFileList)
    if (newFileList.length === 0) {
      form.setFieldsValue({
        attachments: [],
      })
    }
  }, [fileList])

  const handleCreateOutgoingDocument = async submitData => {
    try {
      loadingAnimationStore.showSpinner(true)
      await documentStore.createOutgoingDocument(submitData)
      handleCancelCreate()
      message.success('Tạo văn bản thành công!')

    } catch (error) {
      console.log(error)
      message.error(error?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const onFinish = values => {
    if (selectedUserList.length === 0) {
      return message.warning('Công ty nhận không được để trống!')
    }
    const {
      code,
      promulgationDate,
      documentType,
      documentTypeName,
      subject,
      content,
      competence,
      signer,
      signer_position,
      dueDate,
      priority,
      sphereOfPromulgation,
      typeNotation,
      promulgationAmount,
      pageAmount,
      appendixes,
      steeringType,
      toPlaces,
      docReason,
    } = values

    const selectUserDataValue = selectUserData.map(user => {
      return {
        id: user.id,
        type: 'USER',
      }
    })
    const selectGroupDataValue = selectGroupData.map(group => {
      return {
        id: group.id,
        type: 'GRP',
      }
    })

    const listUserToValue = [...selectUserDataValue, ...selectGroupDataValue]

    const submitData = {
      code: code,
      promulgationDate: promulgationDate,
      documentType: documentType,
      documentTypeName: documentTypeName,
      subject: subject,
      content: content,
      competence: competence,
      signer: signer,
      signer_position: signer_position,
      dueDate: dueDate,
      priority: priority,
      sphereOfPromulgation: sphereOfPromulgation,
      typeNotation: typeNotation,
      promulgationAmount: promulgationAmount,
      pageAmount: pageAmount,
      appendixes: appendixes,
      steeringType: steeringType,
      toPlaces: toPlaces,
      docReason: docReason,
      listUserTo: listUserToValue,
    }
    const uploadFileList = fileList ? fileList.filter(file => !file.isSigned).filter((elem, index, fileList) =>
      fileList.findIndex(file => (file.name === elem.name)) === index).map(file => file.originFileObj) : []
    const fileSignedList = fileList.filter(file => file.isSigned).map(file => file.id)
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    Promise.all(batchUploadArr)
      .then(response => {
        submitData.attachments = response.map(response => response.data.fileId).concat(fileSignedList)
        handleCreateOutgoingDocument(submitData)
        setFileList([])
      })
      .catch(error => {
        setLoadings(prevLoadings => {
          const newLoadings = [...prevLoadings]
          newLoadings[0] = false
          return newLoadings
        })
        message.error(error?.vi || 'Không upload được file!')
      })
  }

  const handleSign = (file, index) => {
    if (file.isSigned) return
    if (file.type !== 'application/pdf') {
      message.warning('Chỉ ký được file PDF, vui lòng chọn file khác!')
      return

    }
    const formData = new FormData()
    formData.append('file', file.originFileObj, file.originFileObj.name)
    signedDocumentStore.eSignDocument(formData)
      .then((response) => {
        const { fileSigned, fileNameOrigin, fileNameSigned } = response.data
        const newFileList = [...fileList]
        newFileList[index] = {
          name: fileNameSigned,
          id: fileSigned,
          uid: file.uid,
          isSigned: true,
        }
        setFileList(newFileList)
        message.success(`Thêm văn bản ký số ${fileNameOrigin} thành công!`)
      })
      .catch(error => {
        message.error(error?.message)
      })
  }

  useEffect(() => {
    commonStore.setPageName(['/connected-document/view/incoming'])
  }, [])

  return (
    <>
      <Helmet>
        <title>
          Tạo mới văn bản | Liên thông viên bản
        </title>
      </Helmet>
      <PageHeading title={'Tạo mới văn bản'} />
      <ContentBlockWrapper>
        <Container maxWidth={820}>
          <Form
            form={form} scrollToFirstError={true}
            name={'create-outgoing-document'}
            layout={'vertical'}
            style={{ paddingTop: '2rem' }}
            onFinish={onFinish}
          >
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Số hiệu'}
                  name={'code'}
                  rules={[
                    { required: true, message: ' Vui lòng nhập số hiệu văn bản!' },
                  ]}
                >
                  <Input maxLength={30} placeholder={'Nhập số hiệu văn bản'} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Loại chỉ đạo'}
                  name={'steeringType'}
                >
                  <Select
                    notFoundContent={<EmptyContent />}
                    placeholder={'-- Chọn loại chỉ đạo --'}>
                    <Option value={'0'}>Không phải là chỉ đạo</Option>
                    <Option value={'1'}>Chỉ đạo</Option>
                    <Option value={'2'}>Báo cáo chỉ đạo</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Cơ quan ban hành'}
                  name={'place'}
                >
                  <Input maxLength={50} placeholder={'Nhập cơ quan ban hành văn bản'} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Ngày ban hành'}
                  name={'promulgationDate'}
                >
                  <DatePicker style={{ width: '100%' }} format={ISO_DATE_FORMAT} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={'Loại văn bản'}
              name={'documentType'}
            >
              <Select
                notFoundContent={<EmptyContent />}
                placeholder={'-- Chọn loại văn bản --'}
              >
                <Option value={'1'}>Văn bản hành chính</Option>
                <Option value={'2'}>Văn bản quy phạm pháp luật</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={'Tên loại văn bản'}
              name={'documentTypeName'}
            >
              <Input maxLength={100} placeholder={'Nhập tên loại văn bản'} />
            </Form.Item>
            <Form.Item
              label={'Trích yếu'}
              name={'subject'}
              rules={[
                { required: true, message: ' Vui lòng nhập trích yếu!' },
                { validator: validator.validateCharactersCannotExceed },
              ]}
            >
              <Input maxLength={500} placeholder={'Nhập trích yếu'} />
            </Form.Item>
            <Form.Item
              label={'Nội dung văn bản'}
              name={'content'}
            >
              <TextArea rows={4}
                        placeholder={'Nhập nội dung văn bản, chỉ áp dụng đối với văn bản dạng phi cấu trúc có độ dài không quá 500 ký tự'} />
            </Form.Item>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Thẩm quyền'}
                  name={'competence'}
                >
                  <Input maxLength={100} placeholder={'Nhập thẩm quyền'} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Người ký'}
                  name={'signer'}
                >
                  <Input maxLength={50} placeholder={'Nhập tên người ký'} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={'Chức vụ người ký văn bản'}
              name={'signer_position'}
            >
              <Input maxLength={500} placeholder={'Nhập chức danh người ký'} />
            </Form.Item>
            <Form.Item
              label={'Hạn trả lời văn bản'}
              name={'dueDate'}
            >
              <DatePicker format={ISO_DATE_FORMAT} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              label={'Độ khẩn'}
              name={'priority'}
            >
              <Select
                notFoundContent={<EmptyContent />}
                placeholder={'-- Chọn độ khẩn --'}>
                <Option value={'0'}>Thường</Option>
                <Option value={'1'}>Khẩn</Option>
                <Option value={'2'}>Thượng khẩn</Option>
                <Option value={'3'}>Hỏa tốc</Option>
                <Option value={'4'}>Hỏa tốc hẹn giờ</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={'Lĩnh vực ban hành'}
              name={'sphereOfPromulgation'}
            >
              <Input maxLength={100} placeholder={'Nhập lĩnh vực ban hành'} />
            </Form.Item>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={24}>
                <Form.Item
                  label={'Ký hiệu đơn vị, tổ chức, chữ viết tắt tên người soạn thảo văn bản'}
                  name={'typeNotation'}
                >
                  <Input maxLength={100}
                         placeholder={'Nhập ký hiệu đơn vị, tổ chức, chữ viết tắt tên người soạn thảo văn bản'} />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item
                  label={'Số lượng ban hành'}
                  name={'promulgationAmount'}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    min={0} max={10000}
                    placeholder={'Nhập số lượng ban hành'}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Tổng số trang của văn bản'}
                  name={'pageAmount'}
                >
                  <InputNumber style={{ width: '100%' }} min={1} max={10000}
                               placeholder={'Nhập tổng số trang của văn bản'} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Ban hành theo chỉ đạo'}
                  name={'direction'}
                  valuePropName='checked'
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={'Phụ lục'}
              name={'appendixes'}
            >
              <Input maxLength={200} placeholder={'Nhập thông tin phụ lục'} />
            </Form.Item>
            <Form.Item
              label={'Nơi nhận'}
              name={'toPlaces'}
            >
              <Input maxLength={1000} placeholder={'Nhập nơi nhận'} />
            </Form.Item>
            <Form.Item
              label={'Lý do ban hành'}
              name={'docReason'}
            >
              <Input maxLength={200} placeholder={'Nhập lí do ban hành'} />
            </Form.Item>
            <Form.Item
              name={'attachments'}
              label={'Tài liệu đính kèm'}
            >
              <Dragger
                // accept={'.pdf'}
                valuePropName={'fileList'} fileList={fileList}
                multiple={true} beforeUpload={handleAddFileToUpload}
                onChange={handleChangeFile}
                showUploadList={false}
              >
                <DraggerUploadStyled>
                  <CloudUploadOutlined />
                  <span>Kéo thả hoặc click vào để tải lên tài liệu</span>
                </DraggerUploadStyled>
              </Dragger>
            </Form.Item>

            {
              fileList.length !== 0 &&
              <UploadFileListWrapper>
                {
                  fileList.map((file, index) =>
                    <UploadFileListItem
                      key={file.uid}
                      theme={commonStore.appTheme}
                      isSigned={file.isSigned}
                    >
                      <PaperClipOutlined />
                      <span>{file.name}</span>
                      {
                        <Tooltip title={'Ký số'}>
                          <Tag color={file.isSigned ? '#2ecc71' : 'blue'} onClick={() => handleSign(file, index)}>
                            {
                              file.isSigned ? 'Đã ký' : 'Ký số'
                            }
                          </Tag>
                        </Tooltip>
                      }
                      <Tooltip title={'Xoá tập tin'}>
                        <DeleteOutlined onClick={() => handleRemoveFileFromUploadList(file.uid)} />
                      </Tooltip>
                    </UploadFileListItem>,
                  )
                }
              </UploadFileListWrapper>
            }
            <SelectTitle>
              <span>
                <span style={{ color: '#ff4d4f', fontFamily: 'SimSun, sans-serif', marginRight: '-4px' }}>* </span>
                <span style={{ marginRight: '10px' }}>Công ty nhận:</span>
              </span>
              <Space>
                <Tooltip title='Chọn người dùng' color={blue}>
                  <Button
                    icon={<UserOutlined style={{ color: blue }} />}
                    onClick={() => handleOpenSelectUserPopup(false)}
                  />
                </Tooltip>
                <Tooltip title='Chọn nhóm' color={'#ffc069'}>
                  <Button
                    icon={<TeamOutlined />}
                    style={{ color: yellowPrimary }}
                    onClick={() => handleOpenSelectGroupPopup(false)}
                  />
                </Tooltip>
              </Space>
            </SelectTitle>
            <SelectList>
              {
                selectedUserList.map(el => (
                  <Tag
                    className={'tag-selected'}
                    color={'blue'}
                    key={el.id}
                    closable
                    onClose={() => selectPeopleStore.handleRemoveSelect(el)}
                  >
                    <AvatarHasName
                      imgId={el.image_id}
                      size={22}
                      name={el.name}
                      icon={typeof el.id === 'number' && <TeamOutlined />}
                    />
                  </Tag>))
              }
            </SelectList>
            <FormActionFooter>
              <Button type={'danger'} ghost onClick={handleCancelCreate}>
                Huỷ bỏ
              </Button>
              <Button
                loading={loadings[0]}
                onClick={() => enterLoading(0)}
                style={{ marginLeft: 10 }}
                type={'primary'} htmlType={'submit'}>
                Tạo văn bản
              </Button>
            </FormActionFooter>
          </Form>
        </Container>
      </ContentBlockWrapper>
      <SelectPeoplePopup
        isVisibleSelectPeoplePopup={isVisibleSelectUserPopup}
        handleCancelSelectUser={handleCancelSelectUser}
        handleSubmitSelectUser={() => selectPeopleStore.setIsVisibleSelectUserPopup(false)}
      />
      <SelectGroupPopup
        isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
        handleCancelSelectGroup={handleCancelSelectGroup}
        handleSubmitSelectGroup={() => selectPeopleStore.setIsVisibleSelectGroupPopup(false)}
      />
    </>
  )
}

export default inject(
  'commonStore',
  'fileStore',
  'documentStore',
  'signedDocumentStore',
  'selectPeopleStore',
)(observer(ConnectedDocumentCreatePage))
