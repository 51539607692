import styled from 'styled-components'


export const SignatureBox = styled.div`
  .card {
    box-shadow: ${props => props.isSelected ? '0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%)' : null};
    .ant-card-cover {
      background-color: #faf7f77a;
    }

    .img-box {
      height: 220px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 200px;
        width: 100%;
        object-fit: scale-down;
        max-width: fit-content;
      }
    }

    .ant-card-body {
      border-top: 1px solid #eaeaea;
    }
  }

`
