import styled, { css } from 'styled-components'
import { Modal } from 'antd'


export const ViewerWrapper = styled.div`
  overflow-x: hidden;
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  ${props => props.fullHeight === null && css`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  `}
  
  img {
    width: 100% !important;
  }
`
export const ModalWrapper = styled(Modal)`

  .ant-modal-header {
    padding: 10px 24px;
  }

  .ant-modal-body {
    padding: 10px 24px;
  }


`