import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const MessagesRequest = {
  getList: (page, size) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/message/get-recieve-message`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
      params: {
        page: page,
        size: size,
      },
    }),

  sendMessage: (data) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/message/create-message`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),

  getSendMessage: (page, size, owner, systemInfo) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/message/get-recieve-message`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
      params: {
        page: page,
        size: size,
        owner: owner,
        systemInfo: systemInfo,
      },
    }),


  getMessageById: (id, params) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/message/${id}`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
      params: params,
    }),
}

