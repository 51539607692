import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'
import utils from '../utils'

const source = axios.CancelToken.source()

export const DocumentRequest = {
  cancelRequest: () => {
    source.cancel()
  },
  getIncomingDocument: (pageIndex, pageSize, keyword) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/docs/incoming`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
      params: {
        page: pageIndex,
        size: pageSize,
        ...(!utils.isNullish(keyword) && { keyword: keyword }),
      },
      cancelToken: source.token,
    }),
  getIncomingDocumentDetail: docID =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/docs/incoming/${docID}`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
      cancelToken: source.token,
    }),
  deleteIncomingDocumentById: docId =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/docs/incoming/${docId}`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
      cancelToken: source.token,
    }),
  getIncomingDocumentAttachmentById: (docID, fileId) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/docs/incoming/${docID}/attachments/${fileId}`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      cancelToken: source.token,
    }),
  getOutgoingDocument: (pageIndex, pageSize, keyword) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/docs/outgoing`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
      params: {
        page: pageIndex,
        size: pageSize,
        ...(!utils.isNullish(keyword) && { keyword: keyword }),
      },
      cancelToken: source.token,
    }),

  getLogDocument: (objectId, objectType) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/action-log`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
      params: {
        objectId, objectType,
      },
    }),

  createOutgoingDocument: (documentInfo) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/docs/outgoing`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
      data: documentInfo,
      cancelToken: source.token,
    }),
  getOutgoingDocumentDetail: docID =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/docs/outgoing/${docID}`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
      cancelToken: source.token,
    }),
  getOutgoingDocumentAttachmentById: (docID, fileId) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/docs/outgoing/${docID}/attachments/${fileId}`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      cancelToken: source.token,
    }),
  deleteOutgoingDocumentById: docId =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/docs/outgoing/${docId}`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
      cancelToken: source.token,
    }),
}
