import styled from 'styled-components'

export const SignatureListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
`

export const SignatureBox = styled.div`
  .card {
    background-color: #faf7f77a;
    .img-box {
      height: 220px;
      padding: 25px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 200px;
        width: 100%;
        object-fit: scale-down;
        max-width: fit-content;
      }
    }

    .ant-card-body {
      border-top: 1px solid #eaeaea;
    }
  }

  .ant-card-actions {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    right: 7px;
    bottom: 14px;
    > li {
      margin: 4px 0;
    }
  }
`