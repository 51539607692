import { action, observable } from 'mobx'
import { DocumentRequest } from '../requests/DocumentRequest'

class DocumentStore {
  @observable documentList = []
  @observable actionLogList = []
  @observable totalCount = 0
  @observable pageIndex = 0
  @observable pageSize = 10
  @observable selectedIncomingDocumentDetail = {}
  @observable selectedOutgoingDocumentDetail = {}
  @observable keyword = undefined
  @observable selectedIncomingDocumentAttachment = undefined
  @observable selectedOutgoingDocumentAttachment = undefined

  @action setFilter = (filterName, filterValue) => {
    if (typeof filterName !== 'string') return
    this[filterName] = filterValue
  }
  @action getIncomingDocument = () => {
    return new Promise((resolve, reject) => {
      DocumentRequest.getIncomingDocument(
        this.pageIndex,
        this.pageSize,
        this.keyword,
      ).then(response => {
        const { data, totalElements } = response.data
        this.documentList = data
        this.totalCount = totalElements
        resolve(response)
      }).catch(error => reject(error))
    })
  }

  @action getLogDocument = (objectId, objectType) => {
    return new Promise((resolve, reject) => {
      DocumentRequest.getLogDocument(objectId, objectType)
        .then(response => {
          this.actionLogList = response.data
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  @action clearLogDocument = () => {
    this.actionLogList.length = 0
  }

  @action getIncomingDocumentDetail = docID => {
    return new Promise((resolve, reject) => {
      DocumentRequest.getIncomingDocumentDetail(docID)
        .then(response => {
          this.selectedIncomingDocumentDetail = response.data
          resolve(response)
        }).catch(error => reject(error))
    })
  }
  @action deleteIncomingDocumentById = docID => {
    return new Promise((resolve, reject) => {
      DocumentRequest.deleteIncomingDocumentById(docID)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  @action getIncomingDocumentAttachmentById = (docID, fileId) => {
    return new Promise((resolve, reject) => {
      DocumentRequest.getIncomingDocumentAttachmentById(docID, fileId)
        .then(response => {
          this.selectedIncomingDocumentAttachment = response.data
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  @action clearSelectedIncomingDocument = () => {
    this.selectedIncomingDocumentDetail = {}
    this.selectedIncomingDocumentAttachment = undefined
  }
  @action getOutgoingDocument = () => {
    return new Promise((resolve, reject) => {
      DocumentRequest.getOutgoingDocument(
        this.pageIndex,
        this.pageSize,
        this.keyword,
      ).then(response => {
        const { data, totalElements } = response.data
        this.documentList = data
        this.totalCount = totalElements
        resolve(response)
      }).catch(error => reject(error))
    })
  }

  @action getOutgoingDocumentDetail = docID => {
    return new Promise((resolve, reject) => {
      DocumentRequest.getOutgoingDocumentDetail(docID)
        .then(response => {
          this.selectedOutgoingDocumentDetail = response.data
          resolve(response)
        }).catch(error => reject(error))
    })
  }
  @action createOutgoingDocument = documentInfo => {
    return new Promise((resolve, reject) => {
      DocumentRequest.createOutgoingDocument(documentInfo)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  @action deleteOutgoingDocumentById = docID => {
    return new Promise((resolve, reject) => {
      DocumentRequest.deleteOutgoingDocumentById(docID)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  @action getOutgoingDocumentAttachmentById = (docID, fileId) => {
    return new Promise((resolve, reject) => {
      DocumentRequest.getOutgoingDocumentAttachmentById(docID, fileId)
        .then(response => {
          this.selectedOutgoingDocumentAttachment = response.data
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  @action clearSelectedOutgoingDocument = () => {
    this.selectedOutgoingDocumentDetail = {}
    this.selectedOutgoingDocumentAttachment = undefined
  }
  @action clearDocumentListOnLeave = () => {
    this.documentList.length = 0
    this.totalCount = 0
    this.pageIndex = 0
    this.pageSize = 10
    this.keyword = undefined
  }
}

export default new DocumentStore()
