import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { ViewerWrapper } from './PDFViewerStyled'
import { pdfjs, Document, Page } from 'react-pdf'
import { apiUrl } from './../../config'
import { Empty } from 'antd'
import utils from '../../utils'
import { EmptyText } from '../Common/CellText'

pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.9.359/pdf.worker.min.js'

const PDFViewer = props => {
  const { fileId, fileType } = props
  const [numPages, setNumPages] = useState(null)
  const isFileTrucToVIMC = window.location.pathname.includes('company-work-schedule')
  const param = isFileTrucToVIMC ? '?client=fse-vimc' : ''

  const isPDF = ['pdf'].includes(fileType)
  const isImageAndPDF = ['jpg', 'bmp', 'dib', 'jpe', 'jfif', 'gif', 'tif', 'tiff', 'png', 'heic', 'pdf'].includes(fileType)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  let viewFile = null

  if (utils.isIOSDevice() && isPDF) {
    viewFile = `${apiUrl}/api/v1/images/${fileId}${param}`
  } else {
    if (isImageAndPDF) {
      viewFile = `${apiUrl}/api/v1/images/${fileId}${param}`
    } else {
      viewFile = `https://view.officeapps.live.com/op/embed.aspx?src=${apiUrl}/api/v1/images/${fileId}${param}`
    }
  }

  const renderContent = () => {
    if (!fileId) return (
      <Empty style={{ marginTop: 40 }}
             description={utils.isIOSDevice() ? <EmptyText>Không có văn bản hợp lệ.</EmptyText> :
               <EmptyText>Không có văn bản</EmptyText>} />
    )
    if (utils.isIOSDevice() && isPDF) {
      return (
        <Document
          file={viewFile}
          onLoadSuccess={onDocumentLoadSuccess}
          error='Không tải được tệp.'
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1} />
          ))}
        </Document>
      )
    }
    return (<iframe src={viewFile} style={{ border: 'none', height: '100%', width: '100%', minHeight: '89vh' }} />)
  }

  return (
    <ViewerWrapper fullHeight={fileId}>
      {renderContent()}
    </ViewerWrapper>
  )
}

PDFViewer.propTypes = {
  file: PropTypes.object,
  customStyleObj: PropTypes.object,
}

export default memo(PDFViewer)
