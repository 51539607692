import { QuestionCircleOutlined } from '@ant-design/icons'
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css'
import { Button, Popconfirm, Tooltip, message } from 'antd'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { useMediaQuery } from 'react-responsive'
import { TitleContentBox } from '../../components/Common/CellText'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import ConnectedDocumentIncomingDocHistoryBlock from '../../components/ConnectedDocumentIncomingDocHistoryBlock'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PDFViewer from '../../components/PDFViewer'
import PageHeading from '../../components/PageHeading'
import PopoverWatchTime from '../../components/PopoverWatchTime'
import { OUTGOING_DOCUMENT_STATE } from '../../constants'
import date_format from '../../date_format'
import utils from '../../utils'
import {
  BlockLeft,
  BlockRight,
  DocumentContentWrapper,
  DocumentInfoList,
} from './ConnectedDocumentDetailPageStyled'

const renderInfoText = string => {
  if (string) {
    if (string.length <= 20) return string
    return (
      <Tooltip title={string}>{string.substring(0, 20).concat('...')}</Tooltip>
    )
  }
}

const ConnectedDocumentDetailPage = props => {
  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1299px)',
  })

  const {
    commonStore,
    documentStore,
    match,
    history,
    authenticationStore,
    loadingAnimationStore,
  } = props
  const {
    selectedIncomingDocumentDetail,
    selectedOutgoingDocumentDetail,
    actionLogList,
  } = documentStore
  const { currentUser } = authenticationStore

  const { docID, viewType } = match.params

  const renderPriorityName = priorityId => {
    switch (priorityId) {
      case '0':
      case 0:
        return 'Thường'
      case '1':
      case 1:
        return 'Khẩn'
      case '2':
      case 2:
        return 'Thượng khẩn'
      case '3':
      case 3:
        return 'Hỏa tốc'
      case '4':
      case 4:
        return 'Hỏa tốc hẹn giờ'
      default:
        return ''
    }
  }
  const renderDocumentTypeName = typeId => {
    switch (typeId) {
      case '1':
      case 1:
        return 'Văn bản hành chính'
      case '2':
      case 2:
        return 'Văn bản quy phạm pháp luật'
      default:
        return ''
    }
  }

  const [attachmentForView, setAttachmentForView] = React.useState()

  useEffect(() => {
    switch (viewType) {
      case 'incoming':
        if (!selectedIncomingDocumentDetail?.attachments) return
        setAttachmentForView(
          selectedIncomingDocumentDetail.attachments.find(file =>
            ['pdf', 'doc', 'docx'].includes(utils.getExtensionFile(file.name))
          )
        )
        break
      case 'outgoing':
        if (!selectedOutgoingDocumentDetail.attachments) return
        setAttachmentForView(
          selectedOutgoingDocumentDetail.attachments.find(file =>
            ['pdf', 'doc', 'docx'].includes(utils.getExtensionFile(file.name))
          )
        )
        break
    }
  }, [
    selectedIncomingDocumentDetail.attachments,
    selectedOutgoingDocumentDetail.attachments,
    viewType,
  ])

  const renderAttachmentFile = useMemo(() => {
    return (
      <PDFViewer
        fileId={attachmentForView?.fileId}
        customStyleObj={{ height: isTabletOrMobile ? 500 : 1000 }}
        fileType={utils.getExtensionFile(attachmentForView?.name)}
      />
    )
  }, [attachmentForView])

  const renderGeneralInformation = () => {
    const noiNhan = () => {
      let listUserTo = []
      switch (viewType) {
        case 'incoming':
          listUserTo = selectedIncomingDocumentDetail.listUserTo
          break
        case 'outgoing':
          listUserTo = selectedOutgoingDocumentDetail.listUserTo
          break
      }

      return (
        <>
          <dt>Nơi nhận</dt>
          <dd>
            {listUserTo && listUserTo.length > 0 ? (
              <PopoverWatchTime userList={listUserTo} title={'Nơi nhận'} />
            ) : (
              <p>Không có</p>
            )}
          </dd>
        </>
      )
    }

    const daXem = () => {
      let viewLogs = []
      switch (viewType) {
        case 'incoming':
          viewLogs = selectedIncomingDocumentDetail.view_logs
          break
        case 'outgoing':
          viewLogs = selectedOutgoingDocumentDetail.view_logs
          break
      }

      return (
        <>
          <dt>Đã xem</dt>
          <dd>
            {viewLogs && viewLogs.length > 0 ? (
              <PopoverWatchTime userList={viewLogs} title={'Đã xem'} />
            ) : (
              <p>Không có</p>
            )}
          </dd>
        </>
      )
    }

    if (viewType === 'incoming') {
      return (
        <DocumentInfoList>
          <dt>Số hiệu</dt>
          <dd>
            {selectedIncomingDocumentDetail &&
              selectedIncomingDocumentDetail.code}
          </dd>
          <dt>Loại văn bản</dt>
          <dd>
            {selectedIncomingDocumentDetail.documentType ? (
              renderDocumentTypeName(
                selectedIncomingDocumentDetail.documentType
              )
            ) : (
              <i style={{ color: '#ccc' }}>Không có loại văn bản</i>
            )}
          </dd>
          <dt>CQ ban hành</dt>
          <dd>
            {selectedIncomingDocumentDetail.userFrom &&
              selectedIncomingDocumentDetail.userFrom}
          </dd>
          {noiNhan()}
          <dt>Độ quan trọng</dt>
          <dd>
            {selectedIncomingDocumentDetail.priority ? (
              renderPriorityName(selectedIncomingDocumentDetail.priority)
            ) : (
              <i style={{ color: '#ccc' }}>Không có độ quan trọng</i>
            )}
          </dd>
          <dt>Ngày ban hành</dt>
          <dd>
            {selectedIncomingDocumentDetail.promulgationDate ? (
              date_format.DD_MM_YYYY(
                selectedIncomingDocumentDetail.promulgationDate
              )
            ) : (
              <i style={{ color: '#ccc' }}>Không có ngày ban hành</i>
            )}
          </dd>
          <dt>Người ký</dt>
          <dd>
            {selectedIncomingDocumentDetail.signer ? (
              selectedIncomingDocumentDetail.signer
            ) : (
              <i style={{ color: '#ccc' }}>Không có người ký</i>
            )}
          </dd>
          <dt>File đính kèm</dt>
          <dd>
            <div>
              {selectedIncomingDocumentDetail.attachments ? (
                selectedIncomingDocumentDetail.attachments.map(file => (
                  <FileItem
                    key={file.fileId}
                    file_id={file.fileId}
                    file_name={file.name}
                    file_type={utils.getExtensionFile(file.name)}
                  />
                ))
              ) : (
                <i style={{ color: '#ccc' }}>Không có file đính kèm</i>
              )}
            </div>
          </dd>
          {daXem()}
        </DocumentInfoList>
      )
    }
    if (viewType === 'outgoing') {
      return (
        <DocumentInfoList>
          <dt>Sổ văn bản</dt>
          <dd>
            {selectedOutgoingDocumentDetail.code &&
              renderInfoText(selectedOutgoingDocumentDetail.code)}
          </dd>
          <dt>Nơi ban hành</dt>
          <dd>
            {selectedOutgoingDocumentDetail.userFrom &&
              selectedOutgoingDocumentDetail.userFrom}
          </dd>
          {noiNhan()}
          <dt>Ngày gửi</dt>
          <dd>
            {selectedOutgoingDocumentDetail.createdAt &&
              date_format.DD_MM_YYYY(selectedOutgoingDocumentDetail.createdAt)}
          </dd>
          <dt>File đính kèm</dt>
          <dd>
            <div>
              {selectedOutgoingDocumentDetail.attachments ? (
                selectedOutgoingDocumentDetail.attachments.map(file => (
                  <FileItem
                    key={file.fileId}
                    file_id={file.fileId}
                    file_name={file.name}
                    file_type={utils.getExtensionFile(file.name)}
                  />
                ))
              ) : (
                <i style={{ color: '#ccc' }}>Không có file đính kèm</i>
              )}
            </div>
          </dd>
          {daXem()}
        </DocumentInfoList>
      )
    }
  }

  const handleDeleteDoc = async docID => {
    let dataDeleteDoc
    switch (viewType) {
      case 'incoming':
        dataDeleteDoc = documentStore.deleteIncomingDocumentById(docID)
        break
      case 'outgoing':
        dataDeleteDoc = documentStore.deleteOutgoingDocumentById(docID)
    }
    loadingAnimationStore.showSpinner(true)
    dataDeleteDoc
      .then(() => {
        history.goBack()
        message.success('Thu hồi văn bản thành công!')
        loadingAnimationStore.showSpinner(false)
      })
      .catch(error => {
        console.log(error)
        loadingAnimationStore.showSpinner(false)
      })
  }

  const renderButtonDelete = useCallback(() => {
    let isDisplayOutgoing =
      viewType === 'outgoing' &&
      selectedOutgoingDocumentDetail.state !==
        OUTGOING_DOCUMENT_STATE.DELETE.key
    if (
      isDisplayOutgoing ||
      (viewType === 'incoming' && currentUser?.roles.includes('ROLE_ADMIN'))
    ) {
      return (
        <Popconfirm
          onConfirm={() => handleDeleteDoc(docID)}
          okText={'Đồng ý'}
          cancelText={'Không'}
          okType={'danger'}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          title={'Bạn có muốn thu hồi văn bản này?'}>
          <Button type="primary" danger>
            Thu hồi văn bản
          </Button>
        </Popconfirm>
      )
    }
    return null
  }, [
    viewType,
    currentUser,
    selectedOutgoingDocumentDetail,
    selectedIncomingDocumentDetail,
  ])

  useEffect(() => {
    commonStore.setPageName(['/connected-document/view/incoming'])
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        loadingAnimationStore.showSpinner(true)
        if (viewType === 'incoming') {
          const response = await documentStore.getIncomingDocumentDetail(docID)
          if (response.data.attachments?.length > 0) {
            let showFileIncoming = response.data.attachments.filter(x =>
              x.name.toLowerCase().includes('.pdf')
            )
            await documentStore.getIncomingDocumentAttachmentById(
              docID,
              showFileIncoming[0].fileId
            )
          }
        } else {
          const response = await documentStore.getOutgoingDocumentDetail(docID)
          if (response.data.attachments?.length > 0) {
            let showFileOutgoing = response.data.attachments.filter(x =>
              x.name.toLowerCase().includes('.pdf')
            )
            await documentStore.getOutgoingDocumentAttachmentById(
              docID,
              showFileOutgoing[0].fileId
            )
          }
        }
        await documentStore.getLogDocument(docID, 'DOC_OUTGOING')
      } catch (err) {
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => {
      documentStore.clearSelectedIncomingDocument()
      documentStore.clearSelectedOutgoingDocument()
      documentStore.clearLogDocument()
    }
  }, [docID])

  return (
    <>
      <Helmet>
        <title>
          {viewType === 'incoming'
            ? `${
                selectedIncomingDocumentDetail.subject
                  ? selectedIncomingDocumentDetail.subject
                  : 'Không có trích yếu'
              } | Liên thông văn bản`
            : `${
                selectedOutgoingDocumentDetail.subject
                  ? selectedOutgoingDocumentDetail.subject
                  : 'Không có trích yếu'
              } | Liên thông văn bản`}
        </title>
      </Helmet>
      <PageHeading
        title={
          viewType === 'incoming'
            ? `${
                selectedIncomingDocumentDetail.subject
                  ? selectedIncomingDocumentDetail.subject
                  : 'Không có trích yếu'
              }`
            : `${
                selectedOutgoingDocumentDetail.subject
                  ? selectedOutgoingDocumentDetail.subject
                  : 'Không có trích yếu'
              }`
        }>
        {renderButtonDelete()}
      </PageHeading>
      <DocumentContentWrapper>
        <BlockLeft>{renderAttachmentFile}</BlockLeft>
        <BlockRight>
          <ContentBlockWrapper>
            <TitleContentBox>Thông tin chung</TitleContentBox>
            {renderGeneralInformation()}
          </ContentBlockWrapper>
          {actionLogList.length > 0 && (
            <>
              <div style={{ marginBottom: 15 }} />
              <ContentBlockWrapper>
                <ConnectedDocumentIncomingDocHistoryBlock
                  history={actionLogList}
                  title={
                    viewType === 'incoming'
                      ? 'Lịch sử văn bản đến'
                      : 'Lịch sử văn bản đi'
                  }
                />
              </ContentBlockWrapper>
            </>
          )}
        </BlockRight>
      </DocumentContentWrapper>
    </>
  )
}

ConnectedDocumentDetailPage.propTypes = {
  commonStore: PropTypes.object,
}

export default inject(
  'commonStore',
  'documentStore',
  'fileStore',
  'authenticationStore',
  'loadingAnimationStore'
)(observer(ConnectedDocumentDetailPage))
