import React from 'react'
import { Route, Switch } from 'react-router-dom'
import DefaultLayout from '../../layouts/DefaultLayout'
// Pages
import HomePage from '../../pages/HomePage'
import ConnectedDocumentPage from '../../pages/ConnectedDocumentPage'
import ConnectedDocumentCreatePage from '../../pages/ConnectedDocumentCreatePage'
import ConnectedDocumentDetailPage from '../../pages/ConnectedDocumentDetailPage'
import ConnectedDocumentManagePage from '../../pages/ConnectedDocumentManagePage'
// import SignedDocumentPage from '../../pages/SignedDocumentPage'
import DigitalSignaturePage from '../../pages/DigitalSignaturePage'
import DigitalSignatureCreatePage from '../../pages/DigitalSignatureCreatePage'
import CompanyWorkScheduleListPage from '../../pages/CompanyWorkScheduleListPage'
import MessagesPage from '../../pages/MessagesPage'
import MessageDetailPage from '../../pages/MessageDetailPage'
import UserGroupPage from '../../pages/UserGroupPage'

const ProtectedModule = () => {

  return (
    <DefaultLayout>
      <Switch>
        {/*<Route exact path={'/'} component={HomePage} />*/}
        <Route exact path={'/'} component={MessagesPage} />
        <Route exact path={'/messages/view/:messageID'} component={MessageDetailPage} />
        <Route exact path={'/connected-document/view/:viewType'} component={ConnectedDocumentPage} />
        <Route exact path={'/connected-document/view/:viewType/:docID'} component={ConnectedDocumentDetailPage} />
        <Route exact path={'/connected-document/create'} component={ConnectedDocumentCreatePage} />
        <Route exact path={'/connected-document/manage/:manageType'} component={ConnectedDocumentManagePage} />
        <Route exact path={'/manage/user-group'} component={UserGroupPage} />
        {/*<Route exact path={'/utility/:addOnsType'} component={SignedDocumentPage} />*/}
        <Route exact path={'/utility/digital-signature'} component={DigitalSignaturePage} />
        <Route exact path={'/utility/digital-signature/sign'} component={DigitalSignatureCreatePage} />
        <Route exact path={'/utility/company-work-schedule'} component={CompanyWorkScheduleListPage} />

      </Switch>
    </DefaultLayout>
  )

}

ProtectedModule.propTypes = {}

export default ProtectedModule
