import { action, observable } from 'mobx'
import { MessagesRequest } from '../requests/MessagesRequest'

class MessagesStore {

  @observable messageList = []
  @observable receiveMessageList = []
  @observable messageDetail = {}
  @observable messageTotalCount = 0
  @observable unreadMessageCount = 0
  @observable pageSize = 30
  @observable pageIndex = 0
  @observable replyMessages = []
  @observable queryParamsMessageDetail = {
    owner: null,
    systemInfo: null,
  }

  @action setFilter = (filterName, filterValue) => {
    if (typeof filterName !== 'string') return
    this[filterName] = filterValue
  }

  @action setQueryParamsMessageDetail = payload => {
    this.queryParamsMessageDetail = payload
  }

  @action getList = () => {
    return new Promise((resolve, reject) => {
      MessagesRequest.getList(this.pageIndex, this.pageSize)
        .then(response => {
          this.messageList = [...this.messageList, ...response.data.data]
          this.messageTotalCount = response.data.totalElements
          this.unreadMessageCount = response.data.totalUnread
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action sendMessage = data => {
    return new Promise((resolve, reject) => {
      MessagesRequest.sendMessage(data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @action getMessageById = (id) => {
    return new Promise((resolve, reject) => {
      MessagesRequest.getMessageById(id, this.queryParamsMessageDetail)
        .then(response => {
          this.messageDetail = response.data
          this.replyMessages = response.data.replyMessages
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Clear */
  @action clearMessageStore = () => {
    this.queryParamsMessageDetail = {
      owner: null,
      systemInfo: null,
    }
    this.replyMessages = []
    this.messageList.length = 0
    this.messageTotalCount = 0
    this.unreadMessageCount = 0
    this.messageDetail = {}
    this.pageIndex = 0
    this.pageSize = 30
  }

}

export default new MessagesStore()