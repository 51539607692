import { action } from 'mobx'
import { FileRequest } from '../requests/FileRequest'
import FileDownload from 'js-file-download'
import loadingAnimationStore from './loadingAnimationStore'
import { message } from 'antd'

class FileStore {

  @action uploadFile = formData => {
    return new Promise((resolve, reject) => {
      FileRequest.uploadFile(formData)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  @action getFileByDocIncomingId = (docId, attachmentId) => {
    return new Promise((resolve, reject) => {
      FileRequest.getFileByDocIncomingId(docId, attachmentId)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  @action getFileByDocOutgoingId = (docId, attachmentId) => {
    return new Promise((resolve, reject) => {
      FileRequest.getFileByDocOutgoingId(docId, attachmentId)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @action downloadFile = (file_id, client) => {
    return new Promise((resolve, reject) => {
      FileRequest.downloadAttachment(file_id, client)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action handleDownloadFile = async (id_file, name_file, client) => {
    loadingAnimationStore.showSpinner(true)
    try {
      const { data } = await this.downloadFile(id_file, client)
      const blob = new Blob([data], { type: 'application/pdf' })
      FileDownload(blob, name_file)
    } catch (err) {
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
      console.log(err)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }
}

export default new FileStore()
