import { action, observable } from 'mobx'
import { OrganizationRequest } from '../requests/OrganizationRequest'

class OrganizationStore {
  @observable organizationList = []

  @action getOrganizationList = () => {
    return new Promise((resolve, reject) => {
      OrganizationRequest.getOrganization()
        .then(response => {
          this.organizationList = response.data
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }


}

export default new OrganizationStore()
