import { action, observable } from 'mobx'
import CompanyRequest from '../requests/CompanyRequest'

class CompanyStore {
  @observable companies = []

  getList = async () => {
    let response = await CompanyRequest.getList()
    this.companies = response.data
  }

  @action clearStore = () => {
    this.companies = []
  }
}

export default new CompanyStore()