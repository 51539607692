import {
  AuditOutlined,
  MailOutlined,
  MobileOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Timeline } from 'antd'
import moment from 'moment'
import React from 'react'
import {
  DATE_FORMAT_SLASH,
  HH_mm,
  OUTGOING_DOCUMENT_STATE,
  OUTGOING_DOCUMENT_STATUS,
} from '../../constants'
import utils from '../../utils'
import { EmptyText, TitleContentBox } from '../Common/CellText'
import { TimelineItemWrapper } from './ConnectedDocumentIncomingDocHistoryBlockStyled'

const ConnectedDocumentIncomingDocHistoryBlock = props => {
  const { history } = props
  const { title } = props?.title

  return (
    <>
      <TitleContentBox>{title}</TitleContentBox>
      <Timeline style={{ maxHeight: 374, overflowY: 'auto', paddingTop: 16 }}>
        {history &&
          history.length > 0 &&
          history.map((item, index) => {
            const authoriserInfo = JSON.parse(item.authoriserInfo)
            let fullname = authoriserInfo?.full_name || item.fullnameTruc
            let email = authoriserInfo?.email || item.emailTruc
            let action
            let statusArr = [
              ...Object.entries(OUTGOING_DOCUMENT_STATE),
              ...Object.entries(OUTGOING_DOCUMENT_STATUS),
            ].find(key => {
              return key[0] === item.action
            })
            if (statusArr) {
              action = statusArr[1].value
            } else {
              action = OUTGOING_DOCUMENT_STATUS.SEEN.value
            }
            return (
              <Timeline.Item key={index}>
                <TimelineItemWrapper>
                  <div className="content">
                    <p>{`${action} lúc ${moment(item.createdTime).format(
                      HH_mm
                    )} - ${moment(item.createdTime).format(
                      DATE_FORMAT_SLASH
                    )}`}</p>
                    {fullname && (
                      <p>
                        <UserOutlined />{' '}
                        {fullname ? (
                          utils.getNameInCapitalize(fullname)
                        ) : (
                          <EmptyText>Không rõ</EmptyText>
                        )}
                      </p>
                    )}

                    {email && (
                      <p>
                        <MailOutlined />{' '}
                        {email ? email : <EmptyText>Không rõ</EmptyText>}
                      </p>
                    )}
                    {authoriserInfo?.phone && (
                      <p>
                        <MobileOutlined />{' '}
                        {authoriserInfo?.phone ? (
                          authoriserInfo?.phone
                        ) : (
                          <EmptyText>Không rõ</EmptyText>
                        )}
                      </p>
                    )}

                    {authoriserInfo?.department && (
                      <p>
                        <AuditOutlined />{' '}
                        {authoriserInfo?.department ? (
                          authoriserInfo?.department
                        ) : (
                          <EmptyText>Không rõ</EmptyText>
                        )}
                      </p>
                    )}
                  </div>
                </TimelineItemWrapper>
              </Timeline.Item>
            )
          })}
      </Timeline>
    </>
  )
}

ConnectedDocumentIncomingDocHistoryBlock.propTypes = {}

export default ConnectedDocumentIncomingDocHistoryBlock
