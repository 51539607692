import styled from 'styled-components'
import { Tabs } from 'antd'

export const TabsNavigation = styled(Tabs)`
  .ant-tabs-tab {
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 0;
  }
`
