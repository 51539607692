import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet/es/Helmet'
// Component
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import DocumentList from './TabContent/DocumentList'
import SignatureManagement from './TabContent/SignatureManagement'
import CreateSignatureModal from './CreateSignatureModal/CreateSignatureModal'
// Ant design
import {
  Button,
  message,
  Tabs,
  Upload,
} from 'antd'
import {
  FileTextOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
// Style Components
import { TabsNavigation } from './DigitalSignaturePageStyled'
// Mobx
import { inject, observer } from 'mobx-react'
import PageHeading from '../../components/PageHeading'

const { TabPane } = Tabs

const DigitalSignaturePage = props => {

  const {
    history,
    signedDocumentStore,
    loadingAnimationStore,
    commonStore,
  } = props

  const { pageIndex, pageSize, keyword } = signedDocumentStore

  const [pageContent, setPageContent] = useState(null)

  // 001 : Danh sách tài liệu đã ký
  // 002: Quản lý chữ ký
  const [titlePage, setTitlePage] = useState('001')
  const [isCreateSignatureModalVisible, setIsCreateSignatureModalVisible] = useState(false)

  const handleCloseCreateSignatureModal = () => {
    setIsCreateSignatureModalVisible(false)
  }

  const handleClickCreatNewSignature = () => {
    setIsCreateSignatureModalVisible(true)
  }

  const onSelectDocument = (file) => {
    if (file.file.type !== 'application/pdf') {
      message.warning('Vui lòng chọn tài liệu PDF!')
      return
    }
    const newFile = new Blob([file.file], { type: 'application/pdf' })
    signedDocumentStore.setFileBlob(newFile)
    history.push('/utility/digital-signature/sign')
    signedDocumentStore.setOriginFileObj(file.file)
  }

  const onTabClick = (key) => {
    (key === '/connected-document/view/incoming') ?
      setTitlePage('001') : setTitlePage('002')
  }

  useEffect(() => {
    titlePage === '001' ? setPageContent(<DocumentList />) : setPageContent(<SignatureManagement />)
  }, [titlePage])

  useEffect(() => {
    commonStore.setPageName(['/utility/digital-signature'])
  }, [])

  useEffect(() => {
    (async () => {
      try {
        loadingAnimationStore.setTableLoading(true)
        await signedDocumentStore.getAllDocumentSigned()
      } catch (error) {
        console.log(error)
        message.error(error?.message || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.setTableLoading(false)
      }
    })()
  }, [pageSize, pageIndex, keyword])

  return (
    <>
      <Helmet>
        <title>{'Ký số | Liên thông văn bản'}</title>
      </Helmet>
      <PageHeading title={titlePage === '001' ? 'Danh sách tài liệu đã ký' : 'Quản lý chữ ký'}>
        <Upload
          beforeUpload={() => false}
          showUploadList={false}
          onChange={onSelectDocument}
        >
          <Button type='primary' size={'large'} icon={<FileTextOutlined />}
                  style={{ height: 44, padding: '0 40px', fontSize: 18 }}>
            CHỌN TÀI LIỆU KÝ SỐ
          </Button>
        </Upload>
      </PageHeading>
      <ContentBlockWrapper>
        <div style={{ minHeight: '83vh' }}>
          <TabsNavigation
            onTabClick={onTabClick}
            tabBarExtraContent={
              titlePage === '002' ?
                <Button type='primary' icon={<PlusCircleOutlined />} onClick={handleClickCreatNewSignature}>
                  Tạo chữ ký mới
                </Button>
                : null
            }
          >
            <TabPane tab={'Tài liệu đã ký'} key='/connected-document/view/incoming' forceRender />
            <TabPane tab={'Quản lý chữ ký'} key='/connected-document/view/outgoing' forceRender />
          </TabsNavigation>
          {pageContent}
        </div>
      </ContentBlockWrapper>
      <CreateSignatureModal
        isCreateSignatureModalVisible={isCreateSignatureModalVisible}
        handleCloseCreateSignatureModal={handleCloseCreateSignatureModal}
      />
    </>
  )
}

DigitalSignaturePage.propTypes = {}

export default inject('signedDocumentStore', 'loadingAnimationStore', 'commonStore')(observer(DigitalSignaturePage))