import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
// Ant design
import {
  Divider,
  Input,
  message,
  Pagination,
  Popconfirm,
  Table,
  Tooltip,
} from 'antd'
import {
  DeleteOutlined,
  DownloadOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
// Styled Components
import styled from 'styled-components'
// Mobx
import { inject, observer } from 'mobx-react'
// Other
import FileDownload from 'js-file-download'
import date_format from '../../../date_format'
import loadingAnimationStore from '../../../stores/loadingAnimationStore'

const { Search } = Input

const SearchBarWrapper = styled.div`
  width: 350px;
  margin-bottom: 12px;
`

const DocumentList = props => {

  const {
    signedDocumentStore,
    loadingAnimationStore,
  } = props

  const { documentSignedList, totalCount, pageIndex, pageSize } = signedDocumentStore

  const tableColumns = useMemo(() => [
    {
      title: 'Tên văn bản',
      width: 300,
      render: record => record?.fileNameOrigin,
    },
    {
      title: 'Ngày ký',
      render: record => (
        <>
          {date_format.DD_MM_YYYY(record?.createdDate)}
        </>
      ),
    },
    {
      title: 'Tác vụ',
      fixed: 'right',
      width: 100,
      render: record =>
        <>
          <Tooltip title={'Tải tài liệu'}>
            <DownloadOutlined style={{ color: 'blue' }} onClick={() => handleDownloadDocumentSigned(record)} />
          </Tooltip>
          <Divider type={'vertical'} />
          <Tooltip title={'Xóa'}>
            <Popconfirm
              onConfirm={() => handleDeleteDocumentSigned(record?.id)}
              okText={'Đồng ý'} cancelText={'Không'} okType={'danger'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />} T
              title={'Bạn có muốn xoá văn bản này?'}
            >
              <DeleteOutlined style={{ color: '#fc0000' }} />
            </Popconfirm>
          </Tooltip>
        </>,
    },
  ], [documentSignedList, pageIndex])


  const handleDownloadDocumentSigned = useCallback(file => {
    loadingAnimationStore.showSpinner(true)
    signedDocumentStore.downloadDocumentSigned(file.fileSign)
      .then(response => {
        loadingAnimationStore.showSpinner(false)
        const blob = new Blob([response.data], { type: 'application/pdf' })
        FileDownload(blob, file.fileNameSign)
        return Promise.resolve()
      })
      .catch(error => {
        loadingAnimationStore.showSpinner(false)
        console.log('error', error)
        return Promise.reject()
      })
  }, [])

  const handleDeleteDocumentSigned = useCallback(signedId => {
    loadingAnimationStore.showSpinner(true)
    signedDocumentStore.deleteDocumentSigned(signedId)
      .then(() => {
        if (documentSignedList.length === 1 && pageIndex !== 0) {
          signedDocumentStore.setFilter('pageIndex', pageIndex - 1)
        }
      })
      .then(() => {
        return signedDocumentStore.getAllDocumentSigned()
          .then(() => Promise.resolve())
          .catch(error => {
            console.log('Error get all document signed', error)
            return Promise.reject(error)
          })
      })
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        message.success('Xóa tài liệu thành công!')
      })
      .catch(error => {
        loadingAnimationStore.showSpinner(false)
        console.log(error)
      })
  }, [documentSignedList, pageIndex])

  const handleChangePage = page => {
    const pageIndex = page - 1
    signedDocumentStore.setFilter('pageIndex', pageIndex)
  }

  const handleSearch = (values) => {
    signedDocumentStore.setFilter('pageIndex', 0)
    signedDocumentStore.setFilter('keyword', values && values.trim())
  }

  return (
    <>
      <SearchBarWrapper>
        <Search
          placeholder={'Tìm kiếm theo tên văn bản'}
          onSearch={handleSearch}
          allowClear={true}
        />
      </SearchBarWrapper>
      <Table
        rowKey={record => record.id}
        dataSource={documentSignedList}
        columns={tableColumns}
        pagination={false}
        loading={{
          tip: 'Đang tải tài liệu ký số...',
          spinning: loadingAnimationStore.tableLoading,
        }}
      />
      {
        totalCount > 10 && <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 18,
        }}>
          <Pagination
            current={pageIndex + 1}
            pageSize={pageSize}
            total={totalCount}
            onChange={handleChangePage}
          />
        </div>
      }
    </>
  )
}

DocumentList.propTypes = {}

export default inject('signedDocumentStore', 'loadingAnimationStore')(observer(DocumentList))