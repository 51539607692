import styled from 'styled-components'

export const CommentBlock = styled.div`
  padding: 15px 24px;
  margin-top: 10px;
  min-height: 140px;
  background-color: #fcfcfc;
  border-top: 1px solid #f0f0f0;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

`

export const FormButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0 20px;
`
export const UploadWrapper = styled.span`
  margin: 0 4px;
  position: relative;

  .ant-upload-list {
    position: absolute;
  }
`

export const MessageWrapper = styled.div`
  padding: 8px;
  margin-top: 10px;
  background-color: #fcfcfc;
  border: 1px solid #ccc;

  .title {
    font-weight: bold;
  }
`

export const UploadFileListWrapper = styled.ul`
  margin: 10px 0 15px;
  padding-left: 0 !important;
`