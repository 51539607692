import React from 'react'
import PropTypes from 'prop-types'
import { ViewerWrapper, ModalWrapper } from './FileViewPopupStyled'
import PDFViewer from '../PDFViewer/PDFViewer'
import utils from '../../utils'

const FileViewPopup = props => {

  const { isVisibleFileViewPopup, handleCloseFileViewPopup, file, client } = props

  return (
    <ModalWrapper
      title={`Tài liệu:  ${file.file_name}`}
      visible={isVisibleFileViewPopup}
      onCancel={handleCloseFileViewPopup}
      style={{ top: 20 }}
      width={utils.isIOSDevice() ? 800 : 1100}
      footer={null}
    >
      <ViewerWrapper>
        <PDFViewer
          fileId={file.file_id}
          fileType={utils.getExtensionFile(file.file_name)}
        />
      </ViewerWrapper>
    </ModalWrapper>
  )
}

FileViewPopup.propTypes = {
  isVisibleFileViewPopup: PropTypes.bool.isRequired,
  handleCloseFileViewPopup: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
}

export default FileViewPopup