// Component
import { EmptyText } from '../Common/CellText'
import NormalTag from '../NormalTag/NormalTag'
// Styled component
import { PopoverList, PopoverTitle, TagWrapper } from './PopoverWatchTimeStyled'
// Ant design
import {
  BankOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  HistoryOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Popover } from 'antd'
// Other
import React from 'react'
import { blue, green, yellowPrimary } from '../../color'
import { ASSIGNEE_TYPE } from '../../constants'
import date_format from '../../date_format'

const PopoverWatchTime = props => {
  const { userList, showFullTag } = props
  const [title] = React.useState(props.title)

  const popoverList = listSelected => {
    return (
      <PopoverList>
        <div className={'popover-head'}>
          <b style={{ flexGrow: 2 }}>
            <UserOutlined style={{ color: yellowPrimary, marginRight: 6 }} />
            Người dùng
          </b>
          <b
            style={{
              display: 'inline-block',
              flexGrow: 1,
            }}>
            <HistoryOutlined style={{ color: yellowPrimary, marginRight: 6 }} />
            Xem lần đầu
          </b>
          <b
            style={{
              display: 'inline-block',
              flexGrow: 1,
            }}>
            <HistoryOutlined style={{ color: yellowPrimary, marginRight: 6 }} />
            Xem lần cuối
          </b>
        </div>
        {listSelected &&
          listSelected.map((el, index) => {
            let iconAssigneeType = (
              <UserOutlined style={{ color: blue, marginRight: 6 }} />
            )

            if (el.type === ASSIGNEE_TYPE.DEPARTMENT) {
              iconAssigneeType = (
                <BankOutlined style={{ color: green, marginRight: 6 }} />
              )
            }
            if (el.type === ASSIGNEE_TYPE.GROUP) {
              iconAssigneeType = (
                <TeamOutlined
                  style={{ color: yellowPrimary, marginRight: 6 }}
                />
              )
            }
            const textColor =
              el.type === ASSIGNEE_TYPE.USER
                ? blue
                : el.type === ASSIGNEE_TYPE.DEPARTMENT
                ? green
                : blue
            return (
              <li key={index}>
                <span
                  style={{
                    color: textColor,
                    textTransform: 'capitalize',
                  }}>
                  {iconAssigneeType}
                  {el.name?.toLowerCase()}
                </span>
                <time>
                  {el.first_date_read ? (
                    <>
                      <span>
                        <EyeOutlined style={{ color: green, marginRight: 6 }} />
                        {`${date_format.HH_mm_DD_MM_YYYY(el.first_date_read)}`}
                      </span>
                      {el.first_user_read && (
                        <p style={{ fontSize: 12 }}>
                          bởi <b>{el.first_user_read.fullName}</b>
                        </p>
                      )}
                    </>
                  ) : (
                    <EmptyText>
                      <EyeInvisibleOutlined
                        style={{ color: '#ccc', marginRight: 6 }}
                      />
                      Chưa xem.
                    </EmptyText>
                  )}
                </time>
                <time>
                  {el.last_date_read ? (
                    <>
                      <span>
                        <EyeOutlined style={{ color: green, marginRight: 6 }} />
                        {`${date_format.HH_mm_DD_MM_YYYY(el.last_date_read)}`}
                        {el.last_user_read && (
                          <p style={{ fontSize: 12 }}>
                            bởi <b>{el.last_user_read.fullName}</b>
                          </p>
                        )}
                      </span>
                    </>
                  ) : (
                    <EmptyText>
                      <EyeInvisibleOutlined
                        style={{ color: '#ccc', marginRight: 6 }}
                      />
                      Chưa xem.
                    </EmptyText>
                  )}
                </time>
              </li>
            )
          })}
      </PopoverList>
    )
  }

  return (
    <Popover
      placement="top"
      content={() => popoverList(userList)}
      title={<PopoverTitle>{title}</PopoverTitle>}
      overlayStyle={{
        width: '800px',
      }}
      trigger="click">
      <TagWrapper>
        <NormalTag
          noHover={true}
          showFullTag={showFullTag}
          tags={userList.map(user => user.name?.toLowerCase())}
        />
      </TagWrapper>
    </Popover>
  )
}

PopoverWatchTime.propTypes = {}

export default PopoverWatchTime
