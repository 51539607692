import React, { useEffect, useMemo } from 'react'
import { Avatar, Input, message, Pagination, Select, Space, Table } from 'antd'
import { inject, observer } from 'mobx-react'
import { subStringAvatar } from '../Common/CellText'
import { blue } from '../../color'
import { apiUrl } from './../../config'
import TableFooterWrapper from '../TableFooterWrapper'
import { TableSelectUserWrapper } from './TableSelectUserStyled'

const { Search } = Input

const TableSelectUser = (props) => {

  const {
    userManageStore,
    loadingAnimationStore,
    onSelectUser,
    rowSelection,
    userGroupStore,
  } = props
  const { userList, totalCount, pageIndex, pageSize, userOrFullname } = userManageStore
  const { companyCode } = userGroupStore

  const tableColumn = useMemo(() => [
    {
      title: 'Thông tin',
      render: record => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar style={{ backgroundColor: blue }}
                  src={record.imgId && `${apiUrl}/api/v1/images/${record.imgId}`}>
            {subStringAvatar(record.fullName)}
          </Avatar>
          <div style={{ marginLeft: 10 }}>{record.fullName}</div>
        </div>
      ),
    },
    {
      title: 'Tài khoản',
      render: record => record.userName,
    },
    {
      title: 'Email',
      render: record => record.email,
    },

  ], [userList])

  const handleChangePage = (page) => {
    userManageStore.setFilter('pageIndex', page - 1)
    loadingAnimationStore.setTableLoading(true)
    userManageStore.getUserList()
      .finally(() => loadingAnimationStore.setTableLoading(false))
  }

  const onSearchByName = value => {
    userManageStore.setFilter('pageIndex', 0)
    userManageStore.setFilter('userOrFullname', value && value.trim())
    loadingAnimationStore.setTableLoading(true)
    userManageStore.getUserList()
      .finally(() => {
        loadingAnimationStore.setTableLoading(false)
      })
  }

  const loadByCompany = async (value) => {
    // console.log('loadByCompany', value)
    userManageStore.companyCode = value
    //
    userManageStore.setFilter('userOrFullname', null)
    userManageStore.setFilter('isEnabled', true)

    //reload by company
    loadingAnimationStore.setTableLoading(true)
    try {
      await userManageStore.getUserList()
    } catch (error) {
      message.error(error?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  useEffect(() => {
    loadByCompany(companyCode)

    return () => userManageStore.clearStore()
  }, [companyCode])

  return (
    <TableSelectUserWrapper>
      <Space style={{ marginBottom: 16 }}>
        <span>Tìm kiếm người dùng: </span>
        <Search allowClear onSearch={onSearchByName} placeholder={'Tìm kiếm người dùng theo tên...'}
                style={{ minWidth: 400, marginLeft: 6 }} />
      </Space>
      <Table
        dataSource={userList}
        columns={tableColumn}
        rowKey={record => record.userName}
        pagination={false}
        loading={loadingAnimationStore.tableLoading}
        onRow={(record) => {
          return {
            onClick: () => onSelectUser(record),
          }
        }}
        rowSelection={{ ...rowSelection }}
      />
      <TableFooterWrapper>
        <Pagination
          current={pageIndex + 1}
          pageSize={pageSize}
          total={totalCount}
          hideOnSinglePage={true}
          onChange={handleChangePage}
        />
      </TableFooterWrapper>
    </TableSelectUserWrapper>
  )
}

TableSelectUser.propTypes = {}

export default inject('userManageStore', 'loadingAnimationStore', 'userGroupStore')(observer(TableSelectUser))