import React, { useCallback, useEffect } from 'react'
import { Button, Form, Input, message } from 'antd'
import { ForgotPasswordLink } from './LoginFormStyled'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'

const LoginForm = props => {

  const { authenticationStore } = props
  const { appToken } = authenticationStore
  const history = useHistory()
  const [loginForm] = Form.useForm()

  const onLogin = useCallback(info => {
    const { username, password } = info
    authenticationStore.userLogin(username, password)
      .then(() => {
        return authenticationStore.getCurrentUser()
          .then(response => message.success(`Xin chào, ${response.fullName}!`))
          .catch(error => console.log('Get current user error: ', error))
      })
      .catch(error => message.error(error?.response?.data?.errorMessage?.message))
  }, [])

  useEffect(() => {
    if (appToken) history.push('/')
  }, [appToken])

  return (
    <Form
      form={loginForm}
      layout={'vertical'}
      onFinish={onLogin}
    >
      <Form.Item
        name={'username'}
        label={'Tên đăng nhập'}
        rules={[
          { required: true, message: 'Vui lòng nhập tên đăng nhập' },
        ]}
      >
        <Input placeholder={'Tên đăng nhập'} />
      </Form.Item>
      <Form.Item
        name={'password'}
        label={'Mật khẩu'}
        rules={[
          { required: true, message: 'Vui lòng nhập mật khẩu' },
        ]}
      >
        <Input.Password placeholder={'Mật khẩu'} />
      </Form.Item>
      <Button type={'primary'} htmlType={'submit'} block>
        ĐĂNG NHẬP
      </Button>
      <ForgotPasswordLink to={'/auth/forgot-password'}>
        Quên mật khẩu
      </ForgotPasswordLink>
    </Form>
  )
}

LoginForm.propTypes = {}

export default inject(
  'authenticationStore',
)(observer(LoginForm))
