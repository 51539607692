import styled from 'styled-components'

export const SignatureCanvasWrapper = styled.div`
  border: 1px solid #dee2e6 !important;
  border-radius: 5px;
  width: 600px;
  overflow: hidden;
  padding: 5px;
  background-color: #F5F5F5;
  cursor: crosshair;
`

export const ColorPickerWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  margin-bottom: 10px;
`

export const ColorSelected = styled.div`
  width: 55px;
  height: 25px;
  padding: 3px;
  margin-left: 10px;
  border: 1px solid #dee2e6 !important;
  border-radius: 4px;

  span {
    display: block;
    height: 100%;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 15px;
  margin-top: 15px;


`