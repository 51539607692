import styled from 'styled-components'

export const DocumentWrapper = styled.div`
  height: auto;
  padding-bottom: 10px;
  position: relative;
  .react-pdf__Document {
    display: flex;
    justify-content: center;
  }
  
  .document-wrapper {
    position: relative;
    
    .spin-box {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 300px;
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  
`


export const DocumentController = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

export const ThumbnailsWrapper = styled.div`
  overflow: auto;
  height: 900px;
  border-right: 3px solid #e4e4e4;

  .rpv-thumbnail-list {
    height: auto;
  }

  .rpv-thumbnail-item {
    height: 150px;
  }
  .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .thumbnail-box {
    width: 145px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5px;
    padding: 10px 10px 0 10px;
    cursor: pointer;
    transition: 0.3s;
    margin-bottom: 10px;
    &:hover {
      background-color: #ccc;
    }
  }
  .thumbnail-box-active {
    background-color: #ccc;
  }

`
