import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet/es/Helmet'
// Components
import EmptyContent from './../../components/EmptyContent'
import ContentBlockWrapper from './../../components/ContentBlockWrapper'
import PickSignatureModal from './PickSignatureModal/PickSignatureModal'
// Layout
// Ant design
import { Button, message, Row, Col, Modal, Spin } from 'antd'
import {
  CloseCircleOutlined,
  EditOutlined,
  FormOutlined,
  LeftOutlined,
  RightOutlined,
  InfoCircleTwoTone,
} from '@ant-design/icons'
// Mobx
import { inject, observer } from 'mobx-react'
// React PDF view
// import '@react-pdf-viewer/thumbnail/lib/styles/index.css'
// import '@react-pdf-viewer/core/lib/styles/index.css'
// import '@react-pdf-viewer/toolbar/lib/styles/index.css'

// Styled Components
import { DocumentWrapper, ThumbnailsWrapper, DocumentController } from './DigitalSignatureCreatePageStyled'

// Konva
import { Stage, Layer } from 'react-konva'
import Rectangle from './Rectangle'
import { apiUrl } from '../../config'

import { Document, Page, pdfjs } from 'react-pdf'
import PageHeading from '../../components/PageHeading'

pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.9.359/pdf.worker.min.js'

const initialRectangles = [
  {
    x: 10,
    y: 10,
    width: 150,
    height: 150,
    id: 'rect1',
  },
]

const { confirm } = Modal

const DigitalSignatureCreatePage = props => {

  const {
    history,
    signedDocumentStore,
    loadingAnimationStore,
    fileStore,
  } = props

  const { fileBlob, originFileObj } = signedDocumentStore

  const [rectangles, setRectangles] = useState(null)
  const [selectedId, selectShape] = useState(null)
  const [isVisiblePickSignatureModal, setIsVisiblePickSignatureModal] = useState(false)
  const [styleCanvas, setStyleCanvas] = useState([80, 900, null])
  const [idImg, setIdImg] = useState(null)

  const [numPages, setNumPages] = useState(null)
  const [thumbnails, setThumbnails] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [loadedPdf, setLoadedPdf] = useState()

  const handleCancelSign = () => {
    history.push('/utility/digital-signature')
    message.info('Bạn đã hủy ký số tài liệu!')
  }

  const handleOpenSignatureModal = () => {
    setIsVisiblePickSignatureModal(true)
  }

  const handleClosePickSignatureModal = () => {
    setIsVisiblePickSignatureModal(false)
  }

  const handleSelectSignature = (signId, ratioImg) => {
    setIsVisiblePickSignatureModal(false)
    if (!signId) return
    setIdImg(signId)
    // set tỉ lệ chữ ký thể tỉ lể ảnh chữ ký
    setRectangles([
      {
        x: 10,
        y: 10,
        width: 150,
        height: 150 / ratioImg,
        id: 'rect1',
      },
    ])
  }

  const handleSign = () => {
    loadingAnimationStore.showSpinner(true)
    const formData = new FormData()
    formData.append('file', originFileObj)
    fileStore.uploadFile(formData)
      .then(res => {
        let dataSubmit
        if (idImg) {
          dataSubmit = {
            image: idImg,
            reason: 'Signed',
            location: 'VietNam',
            contactInfo: '',
            isVisible: 1,
            page: pageNumber,
            llx: rectangles[0].x,
            lly: styleCanvas[1] - rectangles[0].height - rectangles[0].y,
            urx: (rectangles[0].width * 2),
            ury: rectangles[0].height,
            listSignFile: [
              {
                fileId: res.data.fileId,
              },
            ],
          }
        } else {
          dataSubmit = {
            image: '60ac5e444eddb22d40cab8a1',
            reason: 'Signed',
            location: 'VietNam',
            contactInfo: '',
            isVisible: 1,
            page: 1,
            llx: -20,
            lly: styleCanvas[1] - 50,
            urx: 300,
            ury: 34.3,
            listSignFile: [
              {
                fileId: res.data.fileId,
              },
            ],
          }
        }
        signedDocumentStore.signDocumentSavis(dataSubmit)
          .then(() => {
            loadingAnimationStore.showSpinner(false)
            history.push('/utility/digital-signature')
            message.success('Ký số thành công!')
          })
          .catch(err => {
            console.log(err)
            loadingAnimationStore.showSpinner(false)
            message.error('Ký số thất bại!')
          })
      })
      .catch(err => {
        console.log(err)
        loadingAnimationStore.showSpinner(false)
      })
  }

  useEffect(() => {
    if (!fileBlob) history.push('/utility/digital-signature')
  }, [fileBlob])

  const showDeleteConfirm = () => {
    confirm({
      title: 'Bạn có chắc chắn muốn ký tài liệu này?',
      icon: <InfoCircleTwoTone />,
      content: '',
      okText: 'Đồng ý',
      okType: 'primary',
      cancelText: 'Hủy bỏ',
      onOk() {
        handleSign()
      },
      onCancel() {
        console.log('Hủy ký')
      },
    })
  }

  const renderPage = async (pdf, pageNumber) => {
    const page = await pdf.getPage(pageNumber)
    const viewport = page.getViewport({ scale: 1 })
    setStyleCanvas([viewport.width, viewport.height, '1px solid #000'])
  }

  const onDocumentLoadSuccess = (pdf) => {
    const { numPages } = pdf
    setNumPages(numPages)
    const thumbnails = []
    for (let i = 0; i < numPages; i++) {
      thumbnails.push(i + 1)
    }
    setThumbnails(thumbnails)
    setLoadedPdf(pdf)
    renderPage(pdf, pageNumber)
  }


  return (
    <>
      <Helmet>
        <title>{'Ký số | Liên thông văn bản'}</title>
      </Helmet>
      <PageHeading title={'Ký số'}>
        <div>
          <Button
            type='primary'
            size={'large'}
            icon={<FormOutlined />}
            onClick={showDeleteConfirm}
            style={{ marginRight: 10, padding: '0 50px' }}
          >
            KÝ TÀI LIỆU
          </Button>
          <Button
            type='primary'
            danger size={'large'}
            icon={<CloseCircleOutlined />}
            onClick={handleCancelSign}
          >
            HỦY KÝ SỐ
          </Button>
        </div>
      </PageHeading>

      <ContentBlockWrapper>
        {
          fileBlob ?
            <Row>
              <Col span={4}>
                <ThumbnailsWrapper>
                  <Document
                    file={originFileObj}
                    loading={<div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 170 }}>
                      <Spin />
                    </div>}
                  >
                    {
                      thumbnails.map((thumbnailNumber) => {
                        return <div
                          className={thumbnailNumber === pageNumber ? 'thumbnail-box thumbnail-box-active' : 'thumbnail-box'}
                          onClick={() => setPageNumber(thumbnailNumber)}
                          key={thumbnailNumber}
                        >
                          <Page
                            pageNumber={thumbnailNumber}
                            loading={null}
                            width={125}
                          />
                          <div style={{ fontWeight: 'bold' }}>{thumbnailNumber}</div>
                        </div>
                      })
                    }
                  </Document>
                </ThumbnailsWrapper>
              </Col>
              <Col span={20}>
                <DocumentWrapper>
                  <DocumentController>
                    <div style={{ padding: '0px 2px' }}>
                      <Button
                        type='primary'
                        icon={<EditOutlined />}
                        onClick={handleOpenSignatureModal}
                      >
                        Chọn chữ ký
                      </Button>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Button
                        style={{ width: 42 }}
                        onClick={() => {
                          if (pageNumber === 1) return
                          setPageNumber(pageNumber - 1)
                          renderPage(loadedPdf, pageNumber - 1)
                        }}
                        icon={<LeftOutlined />}
                      />
                      <p style={{ margin: '0 10px' }}>
                        {pageNumber}/ {numPages}
                      </p>

                      <Button
                        style={{ width: 42 }}
                        onClick={() => {
                          if (pageNumber === numPages) return
                          setPageNumber(pageNumber + 1)
                          renderPage(loadedPdf, pageNumber + 1)
                        }}
                        icon={<RightOutlined />}
                      />
                    </div>
                  </DocumentController>
                  <div className={'document-wrapper'}>
                    <Document
                      style={{ display: 'flex', justifyContent: 'center' }}
                      file={originFileObj}
                      onLoadSuccess={onDocumentLoadSuccess}
                      loading={<div className={'spin-box'}>
                        <Spin tip='Đang tải tài liệu' />
                      </div>}
                    >
                      <Page
                        pageNumber={pageNumber}
                        loading={null}
                      />
                    </Document>
                  </div>
                  <Stage width={styleCanvas[0]} height={styleCanvas[1]}
                         style={{
                           position: 'absolute',
                           left: '50%',
                           transform: 'translateX(-50%)',
                           border: styleCanvas[2],
                           top: 53,
                           zIndex: 9,
                         }}
                  >
                    <Layer>
                      {rectangles && rectangles.map((rect, i) => {
                        return (
                          <Rectangle
                            urlImg={`${apiUrl}/api/v1/images/${idImg}`}
                            key={i}
                            shapeProps={rect}
                            isSelected={rect.id === selectedId}
                            onSelect={() => {
                              selectShape(rect.id)
                            }}
                            onChange={(newAttrs) => {
                              const rects = rectangles.slice()
                              rects[i] = newAttrs
                              setRectangles(rects)
                            }}
                          />
                        )
                      })}
                    </Layer>
                  </Stage>
                </DocumentWrapper>
              </Col>
            </Row>
            :
            <EmptyContent description={'Hãy chọn tài liệu!'} />
        }
      </ContentBlockWrapper>
      <PickSignatureModal
        isVisiblePickSignatureModal={isVisiblePickSignatureModal}
        handleClosePickSignatureModal={handleClosePickSignatureModal}
        handleSelectSignature={handleSelectSignature}
      />
    </>
  )
}

DigitalSignatureCreatePage.propTypes =
  {}

export default inject(
  'signedDocumentStore',
  'fileStore',
  'loadingAnimationStore',
)(observer(DigitalSignatureCreatePage))
