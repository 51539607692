import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'
import { requests } from './DefaultRequest'

export const SignedDocumentRequest = {

  // getAllDocumentSigned: (pageSize, pageIndex) => requests.get(`/api/v1/sign-document`, true, false, {
  //   page: pageIndex,
  //   size: pageSize,
  // }),
  // eSignDocument: formData => requests.post(`/api/v1/sign-document`, formData),
  // deleteDocumentSigned: signedId => requests.delete(`/api/v1/sign-document/${signedId}`),
  // downloadDocumentSigned: fileId => requests.get(`/api/v1/sign-document/attachments/${fileId}`, false, true, 'arraybuffer'),

  getAllDocumentSigned: (pageSize, pageIndex, keyword) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/sign-document`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
      params: {
        page: pageIndex,
        size: pageSize,
        keyword: keyword,
      },
    }),

  eSignDocument: formData =>
    axios({
      url: `${apiUrl}/api/v1/sign-document/createFptSign`,
      method: 'post',
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    }),

  deleteDocumentSigned: (signedId) =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/sign-document/${signedId}`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
    }),

  downloadDocumentSigned: (fileId) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/sign-document/attachments/${fileId}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    }),

  // savis


  getSignatureList: (pageIndex, pageSize) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/digital-sign`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
      },
      params: {
        page: pageIndex,
        size: pageSize,
      },
    }),

  deleteSignature: (idSignature) =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/digital-sign/${idSignature}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
      },
    }),

  createSignature: (data) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/digital-sign`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
      },
      data: data,
    }),


  signDocumentSavis: (data) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/sign-document/createSavisSign`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
      },
      data: data,
    }),






}