import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet/es/Helmet'
import PageHeading from '../../components/PageHeading'
import moment from 'moment'
import { DatePicker, message, Typography, Empty } from 'antd'
import date_utils from '../../date_utils'
import { inject, observer } from 'mobx-react'
import { DATE_FORMAT_DEFAULT, ISO_DATE_FORMAT } from '../../constants'
import utils from '../../utils'
import { EmptyText, WordBreak } from '../../components/Common/CellText'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import {
  BodyTableLeft,
  BodyTableRight,
  BodyTableWrapper,
  HeaderTableWrapper,
  ItemTableRight,
} from './CompanyWorkScheduleListPageStyled'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'

const { Paragraph } = Typography

const renderTime = value => {
  return `${String(moment(value).hour()).padStart(2, '0')}h${String(moment(value).minute()).padStart(2, '0')}`
}
const renderDate = value => {
  return `${String(moment(value).date()).padStart(2, '0')}/${String(moment(value).month() + 1).padStart(2, '0')}`
}

const CompanyWorkScheduleListPage = props => {

  const { workScheduleStore, commonStore } = props

  const [days, setDays] = useState(date_utils.weekRange(date_utils.current()))
  const [workScheduleList, setWorkScheduledList] = useState([])

  const tableData = workScheduleList?.map(item => {
    return {
      ...item,
      weekDay: moment(item.start_at).isoWeekday(),
    }
  })

  const convertTableData = [
    {
      weekday: 1,
      workSchedule: [],
    },
    {
      weekday: 2,
      workSchedule: [],
    },
    {
      weekday: 3,
      workSchedule: [],
    },
    {
      weekday: 4,
      workSchedule: [],
    },
    {
      weekday: 5,
      workSchedule: [],
    },
    {
      weekday: 6,
      workSchedule: [],
    },
    {
      weekday: 7,
      workSchedule: [],
    },
  ]

  tableData.forEach(item => {
    convertTableData[item.weekDay - 1].workSchedule.push(item)
  })

  const headerTable = (
    <HeaderTableWrapper>
      <span className={'date'}>Ngày tháng</span>
      <span className={'element'}>Nội dung công việc</span>
      <span className={'element'}>Chuẩn bị</span>
      <span className={'element'}>Thành viên tham gia</span>
      <span className={'element'}>Địa điểm</span>
      <span className={'element'}>Chủ trì</span>
    </HeaderTableWrapper>
  )

  const handleChangeWeek = useCallback(date => {
    setDays(date_utils.weekRange(date))
  }, [days])

  let sum = convertTableData.reduce(function(previousValue, currentValue) {
    return previousValue + currentValue.workSchedule.length
  }, 0)

  const [isLoadingWorkSchedule, setIsLoadingWorkSchedule] = useState(false)

  useEffect(() => {
    commonStore.setPageName(['/utility/company-work-schedule'])
  }, [])

  useEffect(() => {
    (async () => {
      try {
        setIsLoadingWorkSchedule(true)
        const data = await workScheduleStore.getPublicWorkSchedule(
          moment(days[0]).format(ISO_DATE_FORMAT), moment(days[6]).format(ISO_DATE_FORMAT))

        setWorkScheduledList(data)
      } catch (err) {
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoadingWorkSchedule(false)
      }
    })()
    return () => {
      workScheduleStore.clearWorkSchedule()
    }
  }, [days])

  return (
    <>
      <Helmet>
        <title>{'Lịch tổng công ty | Liên thông văn bản'}</title>
      </Helmet>
      <PageHeading title='Lịch tổng công ty'>
        <DatePicker
          onChange={handleChangeWeek}
          picker='week' placeholder={'Chọn tuần'}
          format={'Tuần w, YYYY'}
          defaultValue={moment()}
          style={{ height: 32, width: 200 }}
        />
      </PageHeading>
      <ContentBlockWrapper style={{ minHeight: 600 }}>
        {headerTable}
        {convertTableData.length > 0 && (
          <div style={{ marginBottom: -20, position: 'relative' }}>
            {convertTableData.map(el => {
              if (el.workSchedule.length === 0) return null
              return (
                <div
                  key={el.weekday}
                  style={{
                    display: 'flex',
                    marginBottom: 20,
                    border: '1px solid #f0f0f0',
                  }}>
                  <BodyTableLeft>
                    <div>
                      {moment(el.workSchedule[0].start_at).format(
                        DATE_FORMAT_DEFAULT,
                      ) === moment().format(DATE_FORMAT_DEFAULT)
                        ? 'Hôm nay'
                        : utils.renderWeekday(el.weekday)}
                    </div>
                    <div>{renderDate(el.workSchedule[0].start_at)}</div>
                  </BodyTableLeft>
                  <div style={{ flexGrow: 1 }}>
                    {el.workSchedule.map(item => (
                      <BodyTableWrapper key={item.schedule_code}>
                        <BodyTableRight>
                          <ItemTableRight>
                            <strong
                              style={{
                                fontWeight: 'bold',
                                display: 'block',
                              }}>
                              {renderTime(item.start_at)}
                              {item.end_at &&
                              ` đến ${renderTime(item.end_at)}`}
                            </strong>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}>
                              <Paragraph
                                style={{ marginBottom: 0 }}
                                ellipsis={{ rows: 100 }}>
                                {item.event_notice &&
                                utils.removeHtml(item.event_notice)}
                              </Paragraph>
                            </div>
                            <strong>Tài liệu đính kèm:</strong>
                            {
                              item.file_ids?.length ? item.file_ids.map(file =>
                                  <FileItem
                                    key={file.file_id}
                                    file_id={file.file_id}
                                    file_name={file.file_title}
                                    file_type={utils.getExtensionFile(file.file_title)}
                                  />) :
                                <EmptyText>Không có tài liệu đính kèm.</EmptyText>
                            }
                          </ItemTableRight>
                          <ItemTableRight>
                            {item.preparation ? (
                              WordBreak(utils.urlify(item.preparation), true)
                            ) : (
                              <EmptyText>Không có chuẩn bị.</EmptyText>
                            )}
                          </ItemTableRight>
                          <ItemTableRight>
                            {item.attenders ? (
                              item.attenders
                            ) : (
                              <EmptyText>
                                Không có thành viên tham gia.
                              </EmptyText>
                            )}
                          </ItemTableRight>
                          <ItemTableRight>
                            {item.location ? WordBreak(utils.urlify(item.location), true)
                              : (
                                <EmptyText>Không có địa điểm.</EmptyText>
                              )}
                          </ItemTableRight>
                          <ItemTableRight>
                            {item.host ? (
                              item.host
                            ) : (
                              <EmptyText>Không có chủ trì.</EmptyText>
                            )}
                          </ItemTableRight>
                        </BodyTableRight>
                      </BodyTableWrapper>
                    ))}
                  </div>
                </div>
              )
            })}
            <SpinnerInlineComponent
              isLoading={isLoadingWorkSchedule}
              sizeSpin={'small'}
              tip={'Đang tải lịch cơ quan'}
              alignItems
              noBackground
            />
          </div>
        )}
        {sum === 0 && !isLoadingWorkSchedule && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={'Không có dữ liệu'}
            style={{ marginTop: 50 }}
          />
        )}
      </ContentBlockWrapper>
    </>
  )
}

CompanyWorkScheduleListPage.propTypes = {}

export default inject('workScheduleStore', 'commonStore')(observer(CompanyWorkScheduleListPage))