import styled from 'styled-components'

export const MessageInfo = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;

  .assign {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    ul {
      justify-content: flex-start;
    }
  }

  dt, dd {
    padding: 1.5rem 0;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }

  dt {
    width: 125px;
    font-weight: 700;

    &:after {
      display: inline-block;
      content: ':';
    }

    &:nth-last-child(2) {
      border-bottom: none
    }
  }

  dd {
    width: calc(100% - 130px);
    word-break: break-all;

    &:last-child {
      border-bottom: none
    }
  }
`