import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// Mobx
import { inject, observer } from 'mobx-react'
// Components
import EmptyContent from '../../../components/EmptyContent'
// Ant design
import { Card, Row, Col, Button, Modal, Checkbox } from 'antd'
// Styled Components
import { SignatureBox } from './PickSignatureModalStyled'
import { toJS } from 'mobx'
import { apiUrl } from '../../../config'

const { Meta } = Card

const PickSignatureModal = props => {

  const {
    signedDocumentStore,
    isVisiblePickSignatureModal,
    handleClosePickSignatureModal,
    handleSelectSignature,
    loadingAnimationStore,
  } = props

  const { signatureList } = signedDocumentStore

  const [signatureSelected, setSignatureSelected] = useState(null)
  const [ratioImg, setRatioImg] = useState(1)

  const handlePickSignature = (signId) => {
    setSignatureSelected(signId)
    // lấy tỉ lệ ảnh thật w/h
    const img = document.getElementById( signId)
    setRatioImg(img.naturalWidth/img.naturalHeight)
  }

  useEffect(() => {
    if (signatureList) return
    loadingAnimationStore.showSpinner(true)
    signedDocumentStore.getSignatureList()
      .then(() => loadingAnimationStore.showSpinner(false))
      .catch(err => {
        console.log(err)
        loadingAnimationStore.showSpinner(false)
      })
  }, [])

  return (
    <Modal
      title={<span style={{ fontWeight: 'bold', color: 'rgb(16, 136, 231)' }}>Hãy chọn một chữ ký</span>}
      visible={isVisiblePickSignatureModal}
      onCancel={handleClosePickSignatureModal}
      width={1200}
      style={{ top: 40 }}
      footer={[
        <Button key='cancel' onClick={handleClosePickSignatureModal}>
          Hủy
        </Button>,
        <Button key='submit' type='primary' onClick={() => handleSelectSignature(signatureSelected, ratioImg)}>
          Chọn
        </Button>,
        ,
      ]}
    >
      {
        signatureList ?
          <Row gutter={[30, 30]} style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
            {
              signatureList.map(signature =>
                <Col xs={24} md={12} lg={8} key={signature.id}>
                  <SignatureBox
                    onClick={() => handlePickSignature(signature.signId)}
                    isSelected={signatureSelected && signatureSelected === signature.id}>
                    <Card
                      hoverable
                      className={'card'}
                      cover={
                      <div className={'img-box'}>
                        <img alt='signature' src={`${apiUrl}/api/v1/images/${signature.signId}`} id={signature.signId} />
                      </div>}
                      >
                      <Meta title={
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <span>{signature.signName}</span>
                          <Checkbox checked={signatureSelected && signatureSelected === signature.signId} />
                        </div>
                      }>
                      </Meta>
                    </Card>
                  </SignatureBox>
                </Col>,
              )
            }
          </Row>
          : <EmptyContent description={'Bạn chưa có chữ ký nào, hãy tạo chữ ký!'} />
      }
    </Modal>
  )
}

PickSignatureModal.propTypes =
  {}

export default inject('signedDocumentStore', 'loadingAnimationStore')(observer(PickSignatureModal))
