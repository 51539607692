import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import PageHeading from '../../components/PageHeading'
import { Button, Row, Select, Space } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import AddUserGroupModal from './AddUserGroupModal'
import { inject, observer } from 'mobx-react'
import ListUserGroup from './ListUserGroup'
import EmptyContent from '../../components/EmptyContent'
import { find } from 'lodash-es'

const { Option } = Select

const UserGroupPage = props => {

  const { commonStore, companyStore, userGroupStore } = props
  const [isAddUserGroupModalVisible, setIsAddUserGroupModalVisible] = useState(false)
  const { companies } = companyStore
  const [companyCode, setCompanyCode] = useState()
  const [title, setTitle] = useState()

  const handleCloseUserGroupModal = () => {
    setIsAddUserGroupModalVisible(false)
  }

  const showAddUserGroupModal = () => {
    setIsAddUserGroupModalVisible(true)
  }

  useEffect(() => {
    commonStore.setPageName(['/manage/user-group'])
  }, [])

  useEffect(() => {
    // console.log('companies', toJS(companies))
    if (companies.length > 0) {
      let companyCode = companies[0].code
      setCompanyCode(companyCode)
      onCompanyChange(companyCode)
    }
  }, [companies])

  const onCompanyChange = (value) => {
    // console.log('onCompanyChange', value)
    setCompanyCode(value)
    userGroupStore.companyCode = value

    let selectedCompany = find(companies, { 'code': value })
    setTitle(`Nhóm người dùng ${selectedCompany.name}`)
  }

  return (
    <>
      <Helmet><title>Nhóm người dùng</title></Helmet>
      <PageHeading title={title}>
        <Space direction={'horizontal'}>
          <span>Công ty: </span>
          <Select
            style={{ width: 200 }}
            notFoundContent={<EmptyContent/>}
            onChange={onCompanyChange}
            value={companyCode}
            placeholder={'-- Chọn công ty --'}>
            {
              companies.map(item => <Option key={item.code} value={item.code}>{item.name}</Option>)
            }
          </Select>
        </Space>
      </PageHeading>
      <ContentBlockWrapper>
        <Row style={{ marginBottom: 16 }} justify={'end'}>
          <Button type={'primary'} onClick={showAddUserGroupModal}>
            <PlusCircleOutlined/>
            Tạo nhóm người dùng mới
          </Button>
        </Row>
        <ListUserGroup/>
        <AddUserGroupModal
          isAddUserGroupModalVisible={isAddUserGroupModalVisible}
          handleCloseUserGroupModal={handleCloseUserGroupModal}
        />
      </ContentBlockWrapper>
    </>
  )
}

UserGroupPage.propTypes = {}

export default inject('commonStore', 'companyStore', 'userGroupStore')(observer(UserGroupPage))