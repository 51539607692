import { Input, Pagination, Table, message } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { trimOverLengthString } from '../../../components/Common/CellText'
import TableFooterWrapper from '../../../components/TableFooterWrapper'
import TableHeadingWrapper from '../../../components/TableHeadingWrapper'
import UnreadText from '../../../components/UnreadText/UnreadText'
import {
  OUTGOING_DOCUMENT_STATE,
  OUTGOING_DOCUMENT_STATUS,
} from '../../../constants'
import date_format from '../../../date_format'
import utils from '../../../utils'

const { Search } = Input
const SearchBarWrapper = styled.div`
  min-width: 350px;
`

const IncomingDocList = props => {
  const { documentStore, loadingAnimationStore } = props
  const {
    pageIndex,
    pageSize,
    keyword,
    documentList,
    totalCount,
  } = documentStore
  const history = useHistory()

  const getStatusText = ({ state, action }) => {
    if (
      OUTGOING_DOCUMENT_STATE[state]?.key === OUTGOING_DOCUMENT_STATE.DELETE.key
    ) {
      return OUTGOING_DOCUMENT_STATE.DELETE.value
    }
    if (OUTGOING_DOCUMENT_STATUS[action]) {
      return OUTGOING_DOCUMENT_STATUS[action].value
    }

    if (OUTGOING_DOCUMENT_STATE[state]) {
      return OUTGOING_DOCUMENT_STATE[state].value
    } else {
      return OUTGOING_DOCUMENT_STATUS.SEND.value
    }
  }

  const tableColumns = useMemo(
    () => [
      {
        title: 'Ngày nhận',
        width: 120,
        render: record => (
          <UnreadText
            text={record.createdAt && date_format.DD_MM_YYYY(record.createdAt)}
            isUnread={checkUnread(record)}
          />
        ),
      },
      {
        title: 'Số hiệu',
        width: 150,
        render: record => (
          <UnreadText text={record.code} isUnread={checkUnread(record)} />
        ),
      },
      {
        title: 'Trạng thái',
        width: 100,
        render: record =>
          record.action || record.state ? (
            <UnreadText
              text={getStatusText(record)}
              isUnread={checkUnread(record)}
              width={120}
            />
          ) : (
            <i style={{ color: '#ccc' }}>-</i>
          ),
      },
      {
        title: 'Trích yếu',
        width: 300,
        render: record =>
          record.subject ? (
            <UnreadText
              text={trimOverLengthString(record.subject, 220)}
              isUnread={checkUnread(record)}
            />
          ) : (
            <i style={{ color: '#ccc' }}>Không có trích yếu</i>
          ),
      },
      {
        title: 'Cơ quan ban hành',
        width: 200,
        render: record => (
          <UnreadText text={record.userFrom} isUnread={checkUnread(record)} />
        ),
      },
    ],
    []
  )

  const checkUnread = record => !record.isViewed

  const handleChangePage = useCallback(page => {
    documentStore.setFilter('pageIndex', page - 1)
  }, [])
  const handleSearch = useCallback(keyword => {
    if (utils.isNullish(keyword)) {
      documentStore.setFilter('keyword', undefined)
      return
    }
    documentStore.setFilter('keyword', keyword && keyword.trim())
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        loadingAnimationStore.setTableLoading(true)
        await documentStore.getIncomingDocument()
      } catch (error) {
        console.log(error)
        message.error(error || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.setTableLoading(false)
      }
    })()
  }, [pageIndex, pageSize, keyword])
  useEffect(() => () => documentStore.clearDocumentListOnLeave(), [])

  useEffect(() => {
    console.log('tableLoading', loadingAnimationStore.tableLoading)
  }, [loadingAnimationStore.tableLoading])

  return (
    <>
      <TableHeadingWrapper>
        <SearchBarWrapper>
          <Search
            placeholder={'Tìm kiếm theo trích yếu hoặc số hiệu'}
            onSearch={handleSearch}
            allowClear={true}
          />
        </SearchBarWrapper>
      </TableHeadingWrapper>
      <Table
        onRow={record => {
          return {
            onClick: () =>
              history.push(`/connected-document/view/incoming/${record.id}`),
          }
        }}
        rowKey={record => record.id}
        dataSource={documentList}
        columns={tableColumns}
        pagination={false}
        scroll={{ x: 1000 }}
        loading={{
          tip: 'Đang tải văn bản...',
          spinning: loadingAnimationStore.tableLoading,
        }}
      />
      <TableFooterWrapper>
        <Pagination
          total={totalCount}
          current={pageIndex + 1}
          size={10}
          onChange={handleChangePage}
        />
      </TableFooterWrapper>
    </>
  )
}

export default inject(
  'documentStore',
  'loadingAnimationStore'
)(observer(IncomingDocList))
