import styled from 'styled-components'

export const HeaderTableWrapper = styled.div`
  height: 51px;
  background-color: rgb(44, 101, 172);
  color: white;
  display: flex;
  align-items: center;
  font-weight: 500;
  text-align: center;

  .date {
    padding: 16px;
    width: 110px;
  }

  .element {
    padding: 16px;
    width: calc((100% - 110px) / 5);
  }
`

export const BodyTableWrapper = styled.div`
`
export const BodyTableLeft = styled.strong`
  width: 110px;
  flex: 0 0 110px;

  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: 16px;
  border: 1px solid #CDDAF4 !important;

  div {
    text-align: center;
  }
`
export const BodyTableRight = styled.div`
  display: flex;
  transition: 0.3s;

  &:hover {
    background-color: #edf3ff;
    transition: 0.3s;
  }
`

export const ItemTableRight = styled.div`
  padding: 16px;
  word-break: break-word;
  width: calc(100% / 5);
  border: 1px solid #CDDAF4 !important;
`