import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Tag, ShowAllTagsButton } from './NormalTagStyled'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'

const NormalTag = ({
  tags = [],
  commonStore,
  noHover,
  showFullTag = false,
}) => {
  return (
    <Wrapper noHover={noHover}>
      {showFullTag
        ? tags.map((tag, i) => (
            <Tag key={i} color="blue">
              {tag}
            </Tag>
          ))
        : tags.slice(0, 2).map((tag, i) => (
            <Tag key={i} color="blue">
              {tag}
            </Tag>
          ))}
      {showFullTag ? null : tags.length <= 2 ? null : (
        <ShowAllTagsButton
          onMouseMove={e => {
            // commonStore.setMouseCordinate(e)
          }}>
          ...
        </ShowAllTagsButton>
      )}
      {commonStore.mouseCordinate && (
        <div
          className="tag-wrapper"
          style={{
            left: toJS(commonStore.mouseCordinate.x),
            top: toJS(commonStore.mouseCordinate.y),
          }}>
          {tags.slice(2).map((tag, i) => (
            <Tag key={i} color="blue">
              {tag}
            </Tag>
          ))}
        </div>
      )}
    </Wrapper>
  )
}

NormalTag.propTypes = {
  tags: PropTypes.array,
}

export default inject('commonStore')(observer(NormalTag))
