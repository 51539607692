import { action, observable } from 'mobx'
import { SignedDocumentRequest } from '../requests/SignedDocumentRequest'

class SignedDocumentStore {

  @observable documentSignedList = []
  @observable userDetail = {}
  @observable totalCount = 0
  @observable pageIndex = 0
  @observable pageSize = 9
  @observable keyword = undefined

  @action setFilter = (filterName, filterValue) => {
    if (typeof filterName !== 'string') return
    this[filterName] = filterValue
  }
  @action getAllDocumentSigned = () => {
    return new Promise((resolve, reject) => {
      SignedDocumentRequest.getAllDocumentSigned(this.pageSize, this.pageIndex, this.keyword)
        .then(response => {
          const data = response.data.data
          const totalCount = response.data.totalElements
          this.documentSignedList = data
          this.totalCount = totalCount
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  @action eSignDocument = formData => {
    return new Promise((resolve, reject) => {
      SignedDocumentRequest.eSignDocument(formData)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  @action deleteDocumentSigned = (signedId) => {
    return new Promise((resolve, reject) => {
      SignedDocumentRequest.deleteDocumentSigned(signedId)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  @action downloadDocumentSigned = (fileId) => {
    return new Promise((resolve, reject) => {
      SignedDocumentRequest.downloadDocumentSigned(fileId)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }


// savis

//
  @observable fileBlob = null

  @action setFileBlob = (file) => {
    this.fileBlob = file
  }

  @observable originFileObj = null
  @action setOriginFileObj = (file) => {
    this.originFileObj = file
  }

  @observable signatureList = undefined

  @observable pageSignatureIndex = 0
  @observable pageSignatureSize = 100

  @action getSignatureList = () => {
    return new Promise((resolve, reject) => {
      SignedDocumentRequest.getSignatureList(
        this.pageSignatureIndex,
        this.pageSignatureSize,
      ).then(response => {
        this.signatureList = response.data.data
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action deleteSignature = (id_signature) => {
    return new Promise((resolve, reject) => {
      SignedDocumentRequest.deleteSignature(id_signature)
        .then(response => {
          this.signatureList = this.signatureList.filter(signature => signature.id !== id_signature)
          resolve(response)
        }).catch(error => {
        reject(error)
      })
    })
  }

  @action createSignature = (data) => {
    return new Promise((resolve, reject) => {
      SignedDocumentRequest.createSignature(data)
        .then(response => {
          console.log(response)
          resolve(response)
        }).catch(error => {
        reject(error)
      })
    })
  }

  @action signDocumentSavis = (data) => {
    return new Promise((resolve, reject) => {
      SignedDocumentRequest.signDocumentSavis(data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default new SignedDocumentStore()