import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Helmet } from 'react-helmet/es/Helmet'
import PageHeading from '../../components/PageHeading'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { EmptyText, TitleContentBox, WordBreak } from '../../components/Common/CellText'
import { MessageInfo } from './MessageDetailPageStyled'
import { DATE_FORMAT_SLASH, HH_mm } from '../../constants'
import moment from 'moment'
import { message } from 'antd'
import utils from '../../utils'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import loadingAnimationStore from '../../stores/loadingAnimationStore'
import ReplyMessage from '../../components/ReplyMessage'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

const MessageDetailPage = props => {

  const { commonStore, messagesStore, history, match, authenticationStore } = props
  const { messageDetail, replyMessages, queryParamsMessageDetail } = messagesStore
  const { currentUser } = authenticationStore

  const { messageID } = match.params
  const location = useLocation()
  const queryStringParse = queryString.parse(location.search)

  useEffect(() => {
    commonStore.setPageName(['/'])
  }, [])

  useEffect(() => {
    if (!currentUser) return
    messagesStore.setQueryParamsMessageDetail({
      ...queryParamsMessageDetail,
      owner: currentUser?.userName,
      systemInfo: 'VIMC',
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      owner: currentUser?.userName,
      systemInfo: 'VIMC',
    }, {
      skipNull: true,
    })
    history.replace(`/messages/view/${messageID}?${queryStringStringify}`)

    ;(async () => {
      loadingAnimationStore.showSpinner(true)
      try {
        await messagesStore.getMessageById(messageID)
      } catch (error) {
        history.push('/')
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => messagesStore.clearMessageStore()
  }, [messageID, currentUser])

  return (
    <>
      <Helmet>
        <title>{'Trang chủ | Liên thông văn bản'}</title>
      </Helmet>
      <PageHeading title={messageDetail.title} />
      <ContentBlockWrapper>
        <TitleContentBox>Thông tin</TitleContentBox>
        <MessageInfo>
          <dt>Người gửi</dt>
          <dd>{messageDetail.ownerName ? utils.getNameInCapitalize(messageDetail.ownerName) :
            <EmptyText>Không rõ</EmptyText>}</dd>
          <dt>Ngày gửi</dt>
          <dd>
            {messageDetail.createdTime ? (
              `${moment(messageDetail.createdTime).format(
                DATE_FORMAT_SLASH,
              )} - ${moment(messageDetail.createdTime).format(HH_mm)}`
            ) : (
              <EmptyText>Không rõ.</EmptyText>
            )}
          </dd>
          <dt>Nội dung</dt>
          <dd>{messageDetail.content ? WordBreak(messageDetail.content, true) : <EmptyText>Không rõ</EmptyText>}</dd>
          <dt>Tài liệu đính kèm</dt>
          <dd>{messageDetail.fileIds?.length ? messageDetail.fileIds.map(file => <FileItem
              key={file.fileId}
              file_id={file.fileId}
              file_name={file.name}
              file_type={utils.getExtensionFile(file.name)}
            />) :
            <EmptyText>Không có.</EmptyText>}</dd>
        </MessageInfo>
      </ContentBlockWrapper>
      {messageDetail.type === 'TASK' && (
        <ReplyMessage
          replyMessages={replyMessages}
          messageID={messageID}
          messageDetail={messageDetail}
        />
      )}
    </>
  )
}

MessageDetailPage.propTypes = {}

export default inject('commonStore', 'messagesStore', 'loadingAnimationStore', 'authenticationStore')(observer(MessageDetailPage))