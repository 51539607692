import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Helmet } from 'react-helmet/es/Helmet'
import moment from 'moment'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { ListItem, ListWrapper, MessageListWrapper } from './MessagesPageStyled'
import { DATE_FORMAT_SLASH } from '../../constants'
import { message, Spin } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import EmptyContent from '../../components/EmptyContent'
import { EmptyText } from '../../components/Common/CellText'
import utils from '../../utils'
import { blue } from '../../color'

const HeaderStyled = {
  display: 'flex',
  justifyContent: 'space-between',
  fontWeight: 'bold',
  backgroundColor: blue,
  borderTopLeftRadius: 6,
  borderTopRightRadius: 6,
  padding: '16px 0',
  color: '#fff',
  margin: '-16px -16px 16px',
}

const MessagesPage = props => {

  const { commonStore, loadingAnimationStore, messagesStore, history } = props
  const { messageList, unreadMessageCount, messageTotalCount, pageIndex } = messagesStore

  const [hasMore, setHasMore] = useState(true)

  const renderHeaderMessage = (
    <div style={HeaderStyled}>
      <span style={{ width: 160, paddingLeft: 20 }}>Người gửi</span>
      <span style={{ width: 'calc(100% - 350px)' }}>Tiêu đề</span>
      <span style={{ width: 120, textAlign: 'center' }}>Ngày gửi</span>
    </div>
  )

  const fetchMoreData = async () => {
    if (messageList.length >= messageTotalCount) {
      setHasMore(false)
      return
    }
    messagesStore.setFilter('pageIndex', pageIndex + 1)
    try {
      await messagesStore.getList()

    } catch (error) {
      console.log(error)
      message.error(error?.vi)
    }
  }

  useEffect(() => {
    commonStore.setPageName(['/'])
  }, [])

  useEffect(() => {
    (async () => {
      try {
        loadingAnimationStore.setTableLoading(true)
        await messagesStore.getList()
      } catch (error) {
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.setTableLoading(false)
      }
    })()
    return () => {
      messagesStore.clearMessageStore()
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{'Trang chủ | Liên thông văn bản'}</title>
      </Helmet>
      <div style={{ marginBottom: 10 }}>{`Bạn có ${unreadMessageCount} tin nhắn mới`}</div>
      <ContentBlockWrapper>
        {renderHeaderMessage}
        <MessageListWrapper id={'divScroll'}>
          <InfiniteScroll
            dataLength={messageList.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <div className={'center-spin'}>
                {
                  messageList.length > 30 ? (<Spin tip='Đang tải...' />) : ''
                }
              </div>
            }
            endMessage={
              <p style={{ textAlign: 'center', marginBottom: 0, paddingTop: 15 }}>
                <b>Bạn đã xem hết tin nhắn!</b>
              </p>
            }
            scrollableTarget={'divScroll'}
          >
            <ListWrapper
              loading={{
                tip: 'Đang tải tin nhắn...',
                spinning: loadingAnimationStore.tableLoading,
              }}
              dataSource={messageList}
              locale={{
                emptyText: <EmptyContent description={'Không có tin nhắn!'} />,
              }}
              renderItem={item => {
                return (
                  <ListItem
                    key={item.messageUserId}
                    className={`${item.isRead ? '' : 'item-unread'}`}
                    onClick={() => history.push(`/messages/view/${item.messageUserId}`)}
                  >
                    <span style={{ width: 150, paddingLeft: 14 }}>
                      {item.ownerName ? utils.getNameInCapitalize(item.ownerName) : <EmptyText>Không rõ</EmptyText>}
                    </span>
                    <span style={{ width: 'calc(100% - 350px)' }}>{item.title ? item.title :
                      <EmptyText>Không rõ</EmptyText>}</span>
                    <span style={{ width: 120, textAlign: 'center' }}>
                      {item.createdTime ? moment(item.createdTime).format(DATE_FORMAT_SLASH) :
                        <EmptyText>Không rõ</EmptyText>}
                    </span>
                  </ListItem>
                )
              }}
            />
          </InfiniteScroll>
        </MessageListWrapper>
      </ContentBlockWrapper>
    </>
  )
}

MessagesPage.propTypes = {}

export default inject('commonStore', 'loadingAnimationStore', 'messagesStore')(observer(MessagesPage))