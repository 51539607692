export const ASSIGNEE_TYPE = {
  USER: 'USER',
  DEPARTMENT: 'DPT',
  GROUP: 'GRP',
  HANDLER: 'PIC',
  FOLLOWER: 'VIEW',
  COMBINER: 'COOR',
  LEADER: 'LEAD',
  CREATE: 'CREATE',
}

// Datetime
export const ISO_DATE_FORMAT = 'YYYY-MM-DD'
export const DD_MM = 'DD-MM'
export const DATE_FORMAT_DEFAULT = 'DD-MM-YYYY'
export const YYYY_MM_DD_HH_mm = 'YYYY-MM-DD HH:mm'
export const DATE_FORMAT_SLASH = 'DD/MM/YYYY'
export const HH_mm = 'HH:mm'
export const OUTGOING_DOCUMENT_STATUS = Object.freeze({
  SEND: {
    key: 'SEND',
    value: 'Chưa đọc',
  },
  SEEN: {
    key: 'SEEN',
    value: 'Đã đọc',
  },
  RECEIVED: {
    key: 'RECEIVED',
    value: 'Đã nhận',
  },
  PROCESSED: {
    key: 'PROCESSED',
    value: 'Đã xử lý',
  },
  REJECTED: {
    key: 'REJECTED',
    value: 'Đã từ chối',
  },
})
export const OUTGOING_DOCUMENT_STATE = Object.freeze({
  NEW: {
    key: 'NEW',
    value: 'Đã tạo mới',
  },
  UPDATE: {
    key: 'UPDATE',
    value: 'Đã chỉnh sửa',
  },
  CHANGE: {
    key: 'CHANGE',
    value: 'Thay đổi nơi nhận',
  },
  DELETE: {
    key: 'DELETE',
    value: 'Đã thu hồi',
  },
})
