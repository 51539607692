import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// Ant design
import { Modal, Button, Input, message, Form } from 'antd'
// Styled Components
import {
  SignatureCanvasWrapper,
  ColorPickerWrapper,
  ColorSelected,
  ButtonWrapper,
} from './SignatureCanvasModalStyled'
// Mobx
import { inject, observer } from 'mobx-react'
// Other
import SignaturePad from 'react-signature-canvas'

const SignatureCanvasModal = props => {

  const {
    isVisibleSignatureCanvasModal,
    handleCancelSignatureCanvas,
    handleCloseCreateSignatureModal,
    signedDocumentStore,
    loadingAnimationStore,
  } = props

  const [form] = Form.useForm()

  const [sigPad, setSigPad] = useState({})
  const clearCanvas = () => {
    sigPad.clear()
  }

  const handleCreateSignature = async (value) => {
    handleCancelSignatureCanvas()
    handleCloseCreateSignatureModal()
    const base64Img = sigPad.getTrimmedCanvas().toDataURL('image/png')
    const fetchedImg = await fetch(base64Img)
    const blobImg = await fetchedImg.blob()
    const fileImg = new File([blobImg], 'canvas sign', { type: 'image/png' })
    const formData = new FormData()
    formData.append('file', fileImg, value.signature_name)
    loadingAnimationStore.showSpinner(true)
    signedDocumentStore.createSignature(formData)
      .then(() => {
        clearCanvas()
        form.resetFields()
        signedDocumentStore.getSignatureList()
          .then(() => {
            loadingAnimationStore.showSpinner(false)
            message.success('Tạo chữ ký thành công!')
          })
          .catch(() => loadingAnimationStore.showSpinner(false))
      })
      .catch(err => {
        console.log(err)
        loadingAnimationStore.showSpinner(false)
      })
  }

  return (
    <Modal
      title={<span style={{ color: '#1088E7', fontWeight: 'bold' }}>CHỮ KÝ TAY</span>}
      visible={isVisibleSignatureCanvasModal}
      footer={null}
      onCancel={handleCancelSignatureCanvas}
      width={650}
    >
      <Form
        layout='vertical'
        onFinish={handleCreateSignature}
        form={form}
      >
        <Form.Item
          label='Tên mẫu chữ ký'
          name='signature_name'
          rules={[{ required: true, message: 'Hãy nhập tên mẫu chữ ký!' }]}
        >
          <Input placeholder={'Nhập tên mẫu chữ ký'} />
        </Form.Item>
        <div style={{ position: 'relative' }}>
          <SignatureCanvasWrapper>
            <SignaturePad
              ref={ref => {
                setSigPad(ref)
              }}
              penColor={'#000'}
              canvasProps={{ width: 600, height: 300, className: 'sigCanvas' }}
            />
          </SignatureCanvasWrapper>
        </div>
        <ButtonWrapper>
          <Button danger ghost onClick={clearCanvas}>Xóa</Button>
          <Button type={'primary'} htmlType={'submit'}>Lưu chữ ký</Button>
        </ButtonWrapper>
      </Form>
    </Modal>
  )
}


SignatureCanvasModal.propTypes = {}

export default inject('signedDocumentStore', 'loadingAnimationStore')(observer(SignatureCanvasModal))
