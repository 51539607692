import { observable, action } from 'mobx'
import { WorkScheduleRequest } from '../requests/WorkScheduleRequest'

class WorkScheduleStore {

  @observable workScheduleList = []

  @action getPublicWorkSchedule = async (fromDate, toDate) => {
    const response = await WorkScheduleRequest.getPublicWorkSchedule(fromDate, toDate)
    return response.data
  }

  @action clearWorkSchedule = () => {
    this.workScheduleList.length = 0
  }
}

export default new WorkScheduleStore()