import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'
import utils from '../utils'

export const FileRequest = {

  uploadFile: formData =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/docs/attachments`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
      data: formData,
    }),

  getFileByDocIncomingId: (docId, attachmentId) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/docs/incoming/${docId}/attachments/${attachmentId}`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    }),
  getFileByDocOutgoingId: (docId, attachmentId) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/docs/outgoing/${docId}/attachments/${attachmentId}`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    }),
  downloadAttachment: (file_id, client) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/docs/attachments/${file_id}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      params: {
        ...(!utils.isNullish(client) && { client }),
      },
    }),
}


