import { Button, Form, Input, Modal, Select, Space, message } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useCallback, useEffect } from 'react'
import EmptyContent from '../EmptyContent'
import TableFooterWrapper from '../TableFooterWrapper'
const { Option } = Select

const CreateEditUserForm = props => {
  const {
    commonStore,
    modalVisibleProps,
    modalCancelProps,
    userManageStore,
    loadingAnimationStore,
    companyStore,
    authenticationStore,
  } = props

  const { isEditMode } = commonStore
  const { userDetail } = userManageStore
  const { companies } = companyStore
  const { currentUser } = authenticationStore
  const [form] = Form.useForm()

  const submitCreateForm = React.useCallback(async values => {
    try {
      loadingAnimationStore.showSpinner(true)
      const authoriserInfoValue = {
        username: values?.staff_username,
        full_name: values?.staff_name,
        department: values?.staff_department,
        email: values?.staff_email,
        phone: values?.staff_phone,
        updateUser: currentUser?.username,
      }

      await userManageStore.createUser({
        ...values,
        ...{ authoriserInfo: JSON.stringify(authoriserInfoValue) },
      })
      await userManageStore.getUserList()
      handleCancelForm()
      message.success('Tạo tài khoản thành công!')
    } catch (error) {
      console.log(error)
      message.error(error?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }, [])
  const submitEditForm = useCallback(
    async values => {
      const { email, fullName, password } = values
      const authoriserInfoValue = {
        username: values?.staff_username,
        full_name: values?.staff_name,
        department: values?.staff_department,
        email: values?.staff_email,
        phone: values?.staff_phone,
        updateUser: currentUser?.username,
      }

      const dataEdit = {
        email: email,
        fullName: fullName,
        authoriserInfo: JSON.stringify(authoriserInfoValue),
      }
      const passwordNew = { passwordNew: password }
      try {
        loadingAnimationStore.showSpinner(true)
        await userManageStore.updateUser(userDetail.id, dataEdit)
        if (password) {
          await userManageStore.updateUserPassword(userDetail.id, passwordNew)
        }
        await userManageStore.getUserList()
        handleCancelForm()
        message.success('Cập nhật tài khoản thành công!')
      } catch (error) {
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    },
    [userDetail]
  )

  const handleCancelForm = useCallback(() => {
    form.resetFields()
    modalCancelProps()
  }, [])
  const onReset = useCallback(() => {
    form.resetFields()
  }, [])

  useEffect(() => {
    const {
      email,
      fullName,
      password,
      userName,
      companyCode,
      authoriserInfo,
    } = userDetail
    const ai = authoriserInfo ? JSON.parse(authoriserInfo) : null
    if (isEditMode) {
      form.setFieldsValue({
        email: email,
        fullName: fullName,
        password: password,
        userName: userName,
        companyCode: companyCode,
        staff_name: ai?.full_name,
        staff_username: ai?.username,
        staff_department: ai?.department,
        staff_email: ai?.email,
        staff_phone: ai?.phone,
      })
    }

    return () =>
      form.setFieldsValue({
        email: '',
        fullName: '',
        password: '',
        userName: '',
        staff_name: '',
        staff_username: '',
        staff_department: '',
        staff_email: '',
        staff_phone: '',
      })
  }, [userDetail, isEditMode])

  return (
    <Modal
      title={isEditMode ? 'Cập nhật thông tin tài khoản' : 'Thêm mới tài khoản'}
      visible={modalVisibleProps}
      onCancel={modalCancelProps}
      footer={false}
      afterClose={onReset}
      destroyOnClose
      forceRender>
      <Form
        form={form}
        layout={'vertical'}
        id={'edit-create-user'}
        onFinish={isEditMode ? submitEditForm : submitCreateForm}>
        <Form.Item
          label={'Công ty:'}
          name={'companyCode'}
          rules={[{ required: true, message: 'Vui lòng chọn công ty!' }]}>
          <Select
            allowClear
            notFoundContent={<EmptyContent />}
            disabled={isEditMode}
            placeholder={'-- Chọn công ty --'}>
            {companies.map(item => (
              <Option key={item.code} value={item.code}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={'Họ và tên:'}
          name={'fullName'}
          rules={[{ required: true, message: 'Vui lòng nhập họ và tên!' }]}>
          <Input autoComplete={'new-password'} placeholder={'Nhập họ và tên'} />
        </Form.Item>
        <Form.Item
          label={'Tên tài khoản:'}
          name={'userName'}
          rules={[{ required: true, message: 'Vui lòng nhập tên tài khoản!' }]}>
          <Input
            autoComplete={'new-password'}
            disabled={isEditMode}
            placeholder={'Nhập tên tài khoản'}
          />
        </Form.Item>
        <Form.Item
          label={'Email:'}
          name={'email'}
          rules={[{ required: true, message: 'Vui lòng nhập email!' }]}>
          <Input autoComplete={'new-password'} placeholder={'Email'} />
        </Form.Item>
        {isEditMode ? (
          <Form.Item label={'Mật khẩu:'} name={'password'}>
            <Input.Password
              autoComplete={'new-password'}
              placeholder={'Mật khẩu'}
            />
          </Form.Item>
        ) : (
          <Form.Item
            label={'Mật khẩu:'}
            name={'password'}
            rules={[{ required: true, message: 'Vui lòng nhập nhập khẩu!' }]}>
            <Input.Password
              autoComplete={'new-password'}
              placeholder={'Mật khẩu'}
            />
          </Form.Item>
        )}
        <Form.Item label={'Tên nhân viên:'} name={'staff_name'}>
          <Input placeholder={'Tên nhân viên'} />
        </Form.Item>
        <Form.Item label={'Username nhân viên:'} name={'staff_username'}>
          <Input placeholder={'Username nhân viên'} />
        </Form.Item>
        <Form.Item label={'Email nhân viên:'} name={'staff_email'}>
          <Input placeholder={'Email nhân viên'} />
        </Form.Item>
        <Form.Item label={'Phòng ban:'} name={'staff_department'}>
          <Input placeholder={'Phòng ban'} />
        </Form.Item>
        <Form.Item label={'SĐT nhân viên:'} name={'staff_phone'}>
          <Input placeholder={'SĐT nhân viên'} />
        </Form.Item>
        <TableFooterWrapper footerModal>
          <Button type={'danger'} ghost onClick={handleCancelForm}>
            Hủy bỏ
          </Button>
          <Space size={'middle'}>
            <Button
              style={isEditMode ? { display: 'none' } : { display: 'block' }}
              onClick={onReset}>
              Nhập lại
            </Button>
            <Button
              type={'primary'}
              htmlType={'submit'}
              form={'edit-create-user'}>
              {isEditMode ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Space>
        </TableFooterWrapper>
      </Form>
    </Modal>
  )
}

CreateEditUserForm.propTypes = {}

export default inject(
  'commonStore',
  'userManageStore',
  'loadingAnimationStore',
  'companyStore',
  'authenticationStore'
)(observer(CreateEditUserForm))
