import styled from 'styled-components'

export const TimelineItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .content {
    width: calc(100%);
    padding-top: 1px;

    p {
      &:first-child {
        font-size: 13px;
        margin-bottom: 10px;
        color: #919699;
      }
    }
  }

  .action {
    width: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    time {
      font-size: 12px;
      padding-top: 2px;
    }

    .anticon {
      margin-left: 5px;
    }
  }
`