import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const CompanyRequest = {
  getList: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/company`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
    }),
}

export default CompanyRequest