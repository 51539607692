import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const OrganizationRequest = {
  getOrganization: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/docs/organizations`,
      headers: {
        'Authorization': `Bearer ${authenticationStore.accessToken}`,
        'Content-Type': 'application/json',
      },
    }),
}
