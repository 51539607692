import styled, { css } from 'styled-components'

export const DocumentContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const BlockLeft = styled.div`
  width: calc(100% - 415px);
  @media screen and (max-width: 1299px) {
    width: 100%;
    margin-bottom: 15px;
  }
`
export const BlockRight = styled.div`
  width: 400px;
  @media screen and (max-width: 1299px) {
    width: 100%;
  }
`

export const DocumentInfoTitle = styled.h2`
  background-color: #ddd;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 700;
`

export const DocumentInfoList = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: -10px;
  }

  dt {
    width: 160px;
    font-weight: 700;
    padding: 0 10px;

    &:after {
      display: inline-block;
      content: ':';
    }
  }

  dd {
    width: calc(100% - 160px);
    word-break: break-all;
  }

  dt, dd {
    min-height: 36px;
    margin-bottom: 0;
  }
`

export const ViewerWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 89vh;
  ${props => props.fullHeight === null && css`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  `}
`
