import React, { useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import commonStore from '../../stores/commonStore'
import { Helmet } from 'react-helmet/es/Helmet'
import PageHeading from '../../components/PageHeading'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { Tabs } from 'antd'
import { TabsNavigation } from './ConnectedDocumentPageStyled'
import IncomingDocList from './TabContent/IncomingDocList'
import OutgoingDocList from './TabContent/OutgoingDocList'

const { TabPane } = Tabs

const ConnectedDocumentPage = props => {

  const pathMatch = useRouteMatch()
  const { viewType } = pathMatch.params
  const history = useHistory()

  const [pageContent, setPageContent] = useState(null)
  const [pageTitle, setPageTitle] = useState('')
  const [pageHeading, setPageHeading] = useState('')
  const [activeTab, setActiveTab] = useState('')

  const renderContent = useCallback(() => {
    switch (viewType) {
      case 'incoming':
        setPageContent(<IncomingDocList />)
        setPageTitle('Văn bản đến | Liên thông văn bản')
        setPageHeading('Văn bản đến')
        break
      case 'outgoing':
        setPageContent(<OutgoingDocList />)
        setPageTitle('Văn bản đi | Liên thông văn bản')
        setPageHeading('Văn bản đi')
        break
    }
  }, [viewType])
  const onChangeTabContent = useCallback(tabKey => {
    history.push(tabKey)
  }, [])

  useEffect(() => {
    commonStore.setPageName(['/connected-document/view/incoming'])
  }, [])
  useEffect(() => {
    switch (viewType) {
      case 'incoming':
        setActiveTab('/connected-document/view/incoming')
        break
      case 'outgoing':
        setActiveTab('/connected-document/view/outgoing')
        break
    }
    renderContent()
  }, [viewType])

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageHeading
        title={pageHeading}
      />
      <ContentBlockWrapper>
        <TabsNavigation activeKey={activeTab} onChange={onChangeTabContent}>
          <TabPane tab={'Văn bản đến'} key='/connected-document/view/incoming' />
          <TabPane tab={'Văn bản đi'} key='/connected-document/view/outgoing' />
        </TabsNavigation>
        {pageContent}
      </ContentBlockWrapper>
    </>
  )
}

export default inject(
  'commonStore',
)(observer(ConnectedDocumentPage))
